import "jquery-ui/ui/widgets/datepicker"
import "jquery.are-you-sure/jquery.are-you-sure"
import {toRemigDate, toIsoDate} from "./date";

function expandCheckedChildContainers() {
    const checkedInputs = $("input:checked");

    checkedInputs.each((i, el) => {
        $(el).closest(".ecrf-enumeration").find(".ecrf-child-container:first").addClass("ecrf-active");
        const parentValue = $(el).data('child-of');
        const parent = $("input[value='" + parentValue + "']");
        if (parent && (parent.prop("checked"))) {
            parent.closest(".ecrf-enumeration").find(".ecrf-child-container:first").addClass("ecrf-active");
        }
    });

    $("option:selected").each((i, el) => {
        const value = $(el).attr('value');
        if (!value) {
            return;
        }

        $(el).closest('.input-group-sm')
            .find('input[data-child-of=' + value + ']')
            .closest('.ecrf-child-container')
            .addClass('ecrf-active');
    });

    $("input[type=text]:not([value=\"\"])").each((i, el) => {
        const parentValue = $(el).data('child-of');
        const parent = $("input[value='" + parentValue + "']");
        if (parent && (parent.prop("checked"))) {
            parent.closest(".ecrf-enumeration").find(".ecrf-child-container:first").addClass("ecrf-active");
        }
    });
}

function expandNestedChildren(childContainer) {
    const selectedChildEnumerations = childContainer.find("input[type=radio]:checked");
    if (selectedChildEnumerations.length > 0) {
        expandChildrenOfRadioEnum(selectedChildEnumerations)
    }

    const selectedChildChecboxes = childContainer.find("input[type=checkbox]:checked");
    if (selectedChildChecboxes.length > 0) {
        expandChildrenOfCheckboxListEnum(selectedChildChecboxes)
    }
}

export function expandChildrenOfCheckboxListEnum(target) {
    const enumeration = target.closest(".ecrf-enumeration"); // Find the enumeration which owns the input
    const isChecked = target.prop('checked');

    const childContainer = enumeration.find(".ecrf-child-container:first");
    if (isChecked) {
        childContainer.addClass("ecrf-active");
        expandNestedChildren(childContainer);
    } else {
        childContainer.removeClass("ecrf-active");
    }
}

export function expandChildrenOfRadioEnum(target) {
    const enumeration = target.closest(".ecrf-enumeration");
    enumeration.siblings().find(".ecrf-child-container").removeClass("ecrf-active");
    const childContainer = enumeration.find(".ecrf-child-container:first");
    childContainer.addClass("ecrf-active");
    expandNestedChildren(childContainer);
}

export function registerEcrf() {
    window.isCollapsed = true;
    expandCheckedChildContainers();

    $("input.ecrf-date").each((_, input) => {
        const value = $(input).val();
        if (value.indexOf("-") !== -1) {
            $(input).val(toRemigDate(value))
        }
    });

    $("input[type=radio]").on('click', (e: JQueryEventObject) =>
        expandChildrenOfRadioEnum($(e.target))
    );

    $("input[type=checkbox].checkbox-list-input").on('click', (e: JQueryEventObject) => {
        expandChildrenOfCheckboxListEnum($(e.target))
    });

    $("select").on('change', (e: JQueryEventObject) => {
        $(e.target).find("option:selected").each((i, el) => {
            const value = $(el).attr('value');
            if (!value) {
                return;
            }

            $(e.target).closest('.input-group-sm')
                .find('input[data-child-of=' + value + ']')
                .closest('.ecrf-child-container')
                .addClass('ecrf-active');
        });

        $(e.target).find("option:not(:selected)").each((i, el) => {
            let value = $(el).attr('value');
            if (!value) {
                return;
            }

            $(e.target).closest('.input-group-sm')
                .find('input[data-child-of=' + value + ']')
                .closest('.ecrf-child-container')
                .removeClass('ecrf-active');
        });
    });

    $(document).keypress(function (e) {
        if (e.which === 3) { // ctrl + shift + c was pressed (on mac)
            const containers = $('.ecrf-child-container');
            if (window.isCollapsed) {
                console.log("Expanding");
                containers.addClass("ecrf-active");
                window.isCollapsed = false
            } else {
                console.log("Collapsing");
                containers.removeClass("ecrf-active");
                expandCheckedChildContainers();
                window.isCollapsed = true
            }
        }
    });

    $.datepicker.setDefaults(
        $.extend($.datepicker.regional['cs'])
    );

    $('.ecrf-date').datepicker({
        dateFormat: 'd.m.yy',
        closeText: "Zavřít",
        prevText: "&#x3C;Dříve",
        nextText: "Později&#x3E;",
        currentText: "Nyní",
        monthNames: [ "leden","únor","březen","duben","květen","červen",
            "červenec","srpen","září","říjen","listopad","prosinec" ],
        monthNamesShort: [ "led","úno","bře","dub","kvě","čer",
            "čvc","srp","zář","říj","lis","pro" ],
        dayNames: [ "neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota" ],
        dayNamesShort: [ "ne", "po", "út", "st", "čt", "pá", "so" ],
        dayNamesMin: [ "ne","po","út","st","čt","pá","so" ],
        weekHeader: "Týd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    });

    $('form.ecrf-form').areYouSure({'message': 'Neuložené změny budou ztraceny. Pokud nechcete ztratit neuložené změny, prosím, odešlete formulář.'});

    $(':radio[readonly]:not(:checked)').attr('disabled', true);
}
