export const SCAU = [
    'ABAKTAL',
    'ABAKTAL 400 MG TABLETY',
    'ABASAGLAR',
    'ABELCET',
    'ABILIFY',
    'ABILIFY MAINTENA',
    'ABRAXANE',
    'ACARIZAX',
    'ACC INJEKT',
    'ACCOFIL',
    'ACCUPRO 10',
    'ACCUPRO 20',
    'ACCUPRO 5',
    'ACCUSOL 35',
    'ACCUSOL 35 POTASSIUM 2 MMOL/L',
    'ACCUSOL 35 POTASSIUM 4 MMOL/L',
    'ACCUZIDE 10',
    'ACCUZIDE 20',
    'ACECOR 400',
    'ACEFA',
    'ACESIAL',
    'ACICLOVIR OLIKLA',
    'ACIDUM FOLICUM LÉČIVA',
    'ACLASTA',
    'ACLEXA',
    'ACNATAC',
    'ACTILYSE',
    'ACTIVELLE',
    'ACTOS',
    'ACTRAPID PENFILL',
    'ADADUT',
    'ADAFIN',
    'ADD INS',
    'ADEMPAS',
    'ADENOCOR',
    'ADENURIC',
    'ADEZOP',
    'ADJUVIN',
    'ADOLOR',
    'ADONEP',
    'ADRENALIN BRADEX',
    'ADRENALIN LÉČIVA',
    'ADRIBLASTINA CS',
    'ADVAGRAF',
    'ADVANTAN',
    'ADVANTAN MASTNÝ KRÉM',
    'ADVANTAN MLÉKO',
    'ADVATE',
    'ADYNOVI',
    'AEDON',
    'AERIUS',
    'AERIVIO SPIROMAX',
    'AERRANE',
    'AFINITOR',
    'AFITEN',
    'AFLODERM',
    'AFLUDITEN',
    'AFONILUM SR',
    'AGAPURIN SR 400',
    'AGEN',
    'AGOFOLLIN DEPOT',
    'AGOLUTIN',
    'AIRFLUSAN FORSPIRO',
    'AIRFLUSAN SPRAYHALER',
    'AKARBOZA MYLAN',
    'AKINETON',
    'AKISTAN',
    'AKNEMYCIN PLUS',
    'AKNENORMIN',
    'AKTIFERRIN',
    'AKTIPROL',
    'AKYNZEO',
    'ALBUNORM 20%',
    'ALBUNORM 5%',
    'ALBUREX 20',
    'ALBUTEIN',
    'ALDARA',
    'ALDURAZYME',
    'ALENDROGEN',
    'ALENDRONAT ACTAVIS',
    'ALENDRONAT SANDOZ 70',
    'ALENDRONATE-TEVA',
    'ALENDRONIC ACID AUROBINDO',
    'ALENDRONIC ACID AUROVITAS',
    'ALENDRONIC ACID/ VITAMIN D3 TEVA',
    'ALERGIMED',
    'ALERID',
    'ALERPALUX',
    'ALETRO',
    'ALEXAN',
    'ALFACALCIDOL CANDE',
    'ALFAMINO 1X400GM',
    'ALFAMINO 6X400 G',
    'ALFARÉ',
    'ALFUZOSIN MYLAN',
    'ALFUZOSTAD',
    'ALGIFEN',
    'ALGIFEN NEO',
    'ALIMTA',
    'ALKERAN',
    'ALLEGRA',
    'ALLERGO-COMOD OČNÍ KAPKY',
    'ALLERGOCROM KOMBI (OČNÍ+NOSNÍ)',
    'ALLERGOCROM NOSNÍ SPREJ',
    'ALLERGOCROM OČNÍ KAPKY',
    'ALLOPURINOL APOTEX',
    'ALMIRAL',
    'ALOPURINOL GLENMARK',
    'ALOPURINOL SANDOZ',
    'ALOXI',
    'ALOZEX',
    'ALPHA D3',
    'ALPHAGAN',
    'ALPICORT',
    'ALPICORT F',
    'ALPRAZOLAM ORION',
    'ALPROSTAN',
    'ALUTARD SQ',
    'ALUTARD SQ HMYZÍ ALERGENY',
    'ALVESCO 160 INHALER',
    'ALYOSTAL PRICK',
    'ALZIL',
    'AMARYL',
    'AMEDO',
    'AMESOS',
    'AMGEVITA',
    'AMICLOTON',
    'AMIKACIN B. BRAUN',
    'AMIKACIN B.BRAUN',
    'AMIKACIN MEDOPHARM',
    'AMILIA',
    'AMIOKORDIN',
    'AMIPTIC',
    'AMISULPRID GENERICS',
    'AMISULPRID MYLAN',
    'AMITRIPTYLIN-SLOVAKOFARMA',
    'AMLATOR',
    'AMLESSA',
    'AMLODIPIN ACCORD',
    'AMLODIPIN ACTAVIS',
    'AMLODIPIN MYLAN',
    'AMLODIPIN VITABALANS',
    'AMLORATIO',
    'AMLOZEK',
    'AMMONAPS',
    'AMOKSIKLAV FORTE 312,5 MG/5ML',
    'AMOKSIKLAV 1 G',
    'AMOKSIKLAV 1,2 G',
    'AMOKSIKLAV 375 MG',
    'AMOKSIKLAV 457 MG/5 ML',
    'AMOKSIKLAV 600 MG',
    'AMOKSIKLAV 625 MG',
    'AMOXICILLIN/CLAVULANIC ACID MYLAN',
    'AMPICILIN 0,5 BIOTIKA',
    'AMPICILIN 1,0 BIOTIKA',
    'AMPRILAN',
    'AMPRILAN H',
    'ANAFRANIL SR 75',
    'ANAFRANIL 25',
    'ANAGRELID MYLAN',
    'ANAGRELID SANDOZ',
    'ANAGRELID STADA',
    'ANAGRELIDE GLENMARK',
    'ANAGRELIDE TEVA',
    'ANAGRELIDE VIPHARM',
    'ANALERGIN',
    'ANALERGIN NEO',
    'ANALGIN',
    'ANAMIX ANANAS A VANILKA',
    'ANAMIX ČOKOLÁDA',
    'ANAPREX',
    'ANASTAR',
    'ANASTROZOL MYLAN',
    'ANASTROZOL-TEVA',
    'ANAYA',
    'ANBINEX',
    'ANDROCUR DEPOT',
    'ANDROCUR 100',
    'ANDROCUR-50',
    'ANDROFIN',
    'ANDROGEL',
    'ANESIA',
    'ANGELIQ',
    'ANIDULAFUNGIN TEVA',
    'ANOPYRIN',
    'ANORO ELLIPTA',
    'ANSILAN',
    'ANTITHROMBIN III BAXALTA',
    'ANTITHROMBIN III NF BAXTER',
    'ANXILA',
    'APAURIN',
    'APIDRA',
    'APIDRA SOLOSTAR',
    'APLERIA',
    'APO-ACEBUTOL',
    'APO-ALLOPURINOL',
    'APO-AMILZIDE 5/50 MG',
    'APO-AMLO',
    'APO-ANASTROZOL',
    'APO-ATENOL',
    'APO-ATORVASTATIN',
    'APO-BICALUTAMID',
    'APO-CITAL',
    'APO-DICLO',
    'APO-DICLO SR 100',
    'APO-DONEPEZIL',
    'APO-FENO',
    'APO-FINAS',
    'APO-FLUCONAZOL',
    'APO-FLUTAMIDE',
    'APO-GAB',
    'APO-METOPROLOL 100',
    'APO-METOPROLOL 50',
    'APO-OME 20',
    'APO-PANTO',
    'APO-PAROX',
    'APO-PERINDO',
    'APO-PERINDO COMBI',
    'APO-RABEPRAZOL',
    'APO-SIMVA',
    'APO-TAMIS',
    'APO-TIC',
    'APO-TRAMADOL/PARACETAMOL',
    'AQUA PRO INIECTIONE ARDEAPHARMA',
    'AQUA PRO INJECTIONE BRAUN',
    'ARANESP',
    'ARAVA',
    'ARCHIFAR',
    'ARCOXIA',
    'ARDEAELYTOSOL CONC. KALIUMCHLORID 7,45%',
    'ARDEAELYTOSOL CONC. NATRIUMCHLORID 10%',
    'ARDEAELYTOSOL CONC. NATRIUMCHLORID 5,85%',
    'ARDEAELYTOSOL CONC. NATRIUMHYDROGENKARBONÁT 4,2%',
    'ARDEAELYTOSOL CONC. NATRIUMHYDROGENKARBONÁT 8,4%',
    'ARDEAELYTOSOL D 1/1',
    'ARDEAELYTOSOL EA 1/1',
    'ARDEAELYTOSOL EL 1/1',
    'ARDEAELYTOSOL EL 1/3',
    'ARDEAELYTOSOL EL 2/3',
    'ARDEAELYTOSOL F 1/1',
    'ARDEAELYTOSOL F 1/2',
    'ARDEAELYTOSOL F 1/3',
    'ARDEAELYTOSOL H 1/1',
    'ARDEAELYTOSOL H 1/2',
    'ARDEAELYTOSOL H 1/3',
    'ARDEAELYTOSOL H 2/3',
    'ARDEAELYTOSOL R 1/1',
    'ARDEAELYTOSOL R 1/3',
    'ARDEAELYTOSOL RL 1/1',
    'ARDEANUTRISOL G 10%',
    'ARDEANUTRISOL G 20%',
    'ARDEANUTRISOL G 40%',
    'ARDEANUTRISOL G 5%',
    'ARDEAOSMOSOL MA 10%',
    'ARDEAOSMOSOL MA 20%',
    'ARDUAN',
    'ARGOFAN',
    'ARGOFAN 150 SR',
    'ARGOFAN 75 SR',
    'ARICEPT',
    'ARICOGAN',
    'ARIMIDEX',
    'ARIONEX',
    'ARIONEX COMBI 50/12,5 MG POTAHOVANÁ TABLETA',
    'ARIPIPRAZOL APOTEX',
    'ARIPIPRAZOL GLENMARK',
    'ARIPIPRAZOL MYLAN PHARMA',
    'ARIPIPRAZOL +PHARMA',
    'ARIPIPRAZOLE ACCORD',
    'ARIPIPRAZOLE SANDOZ',
    'ARIPIPRAZOLE TEVA',
    'ARIPIPRAZOLE ZENTIVA',
    'ARIXTRA',
    'ARKETIS',
    'ARKVIMMA',
    'AROMASIN',
    'AROPILOS',
    'ARTEOPTIC 2%',
    'ARTRILOM',
    'ARTRODAR',
    'ARUFIL',
    'ARULATAN',
    'ARUTIMOL',
    'ARUTIMOL 0,50%',
    'ARYZALERA',
    'ASACOL',
    'ASACOL ENEMA',
    'ASDUTER',
    'ASENTRA 100',
    'ASENTRA 50',
    'ASKETON',
    'ASMANEX',
    'ASOLFENA',
    'ASTEXANA',
    'ATEHEXAL',
    'ATENATIV',
    'ATENOBENE',
    'ATENOLOL AL 100',
    'ATENOLOL AL 25',
    'ATENOLOL AL 50',
    'ATIMOS',
    'ATORDAPIN',
    'ATORIS',
    'ATORIS 20',
    'ATORSTAD',
    'ATORVASTATIN ACTAVIS',
    'ATORVASTATIN KRKA',
    'ATORVASTATIN MYLAN',
    'ATORVASTATIN +PHARMA',
    'ATORVASTATIN RATIOPHARM GMBH',
    'ATORVASTATIN SANECA',
    'ATORVASTATIN XANTIS',
    'ATOSIBAN ACCORD',
    'ATOSIBAN AVMC',
    'ATOSIBAN EVER PHARMA',
    'ATOSIBAN PHARMIDEA',
    'ATRAM',
    'ATRAVEN',
    'ATRILESTO',
    'ATROCELA',
    'ATROPIN BIOTIKA 0,5 MG',
    'ATROPIN BIOTIKA 1 MG',
    'ATROVENT N',
    'ATROVENT 0,025%',
    'AUBAGIO',
    'AUGMENTIN DUO',
    'AUGMENTIN SR',
    'AUGMENTIN 1 G',
    'AUGMENTIN 1,2 G',
    'AUGMENTIN 625 MG',
    'AULIN',
    'AURORIX',
    'AVAMYS',
    'AVASTIN',
    'AVELOX',
    'AVODART',
    'AVONEX',
    'AXETINE',
    'AZARGA',
    'AZATHIOPRIN MEDAC',
    'AZEPO',
    'AZIBIOT',
    'AZIBIOT NEO',
    'AZITROMYCIN MYLAN',
    'AZITROMYCIN SANDOZ',
    'AZITROX',
    'AZOMYR',
    'AZOPT',
    'AZYTER',
    'BACLOFEN-POLPHARMA',
    'BACTROBAN',
    'BACTROBAN NASAL',
    'BALCOGA',
    'BARACLUDE',
    'BATRAFEN',
    'BATRAFEN ROZTOK',
    'BAVENCIO',
    'BEBA ALPREM LC-PUFA',
    'BECLOMET EASYHALER',
    'BECLOMET NASAL AQUA 100 MCG',
    'BELAKNE 0,1% GEL',
    'BELAKNE 0,1% KRÉM',
    'BELODERM',
    'BELOGENT',
    'BELOSALIC',
    'BEMFOLA',
    'BENDAMUSTIN MEDAC',
    'BENDAMUSTINE ACCORD',
    'BENDAMUSTINE GLENMARK',
    'BENDAMUSTINE KABI',
    'BENDAMUSTINE MYLAN',
    'BENDAMUSTINE PHARMAGEN',
    'BENEFIX',
    'BENEMICIN',
    'BENEPALI',
    'BENLYSTA',
    'BERIATE',
    'BERINERT',
    'BERIPLEX',
    'BERODUAL',
    'BERODUAL N',
    'BESTIN 8 MG',
    'BETADINE',
    'BETAFERON',
    'BETAHISTIN ACTAVIS',
    'BETAHISTIN AUROBINDO',
    'BETAHISTIN AUROVITAS',
    'BETAHISTIN MYLAN',
    'BETAHISTIN-RATIOPHARM',
    'BETAKLAV',
    'BETALMIC',
    'BETALOC',
    'BETALOC SR',
    'BETALOC ZOK',
    'BETAMED',
    'BETASERC 16',
    'BETASERC 24',
    'BETASERC 8',
    'BETAXA 20',
    'BETAXOLOL MYLAN',
    'BETAXOLOL PMCS',
    'BETAXOLOL TEVA',
    'BETAXOLOL XANTIS',
    'BETMIGA',
    'BETOPTIC',
    'BETOPTIC S',
    'BEXSERO',
    'BICALUPLEX',
    'BICALUTAGEN',
    'BICALUTANORM',
    'BIGITAL',
    'BIKALARD',
    'BIMATOPROST STADA',
    'BIMICAN',
    'BIMICAN NEO',
    'BINABIC',
    'BINOCRIT',
    'BIODROXIL',
    'BIOFENAC',
    'BIOMIN H',
    'BISEPTOL',
    'BISEPTOL 480',
    'BISOCARD 10',
    'BISOCARD 5',
    'BISOGAMMA 10',
    'BISOGAMMA 5',
    'BISOPROLOL MYLAN',
    'BISOPROLOL PMCS',
    'BISOPROLOL VITABALANS',
    'BISOPROLOL/AMLODIPINE HCS',
    'BISOPROLOL-RATIOPHARM',
    'BISTON',
    'BIXEBRA',
    'BLEOMEDAC',
    'BLESSIN',
    'BLESSIN PLUS H 160/12,5 MG',
    'BLESSIN PLUS H 160/25 MG',
    'BLESSIN PLUS H 80/12,5 MG',
    'BLINCYTO',
    'BLOXAZOC',
    'BONDRONAT',
    'BONDULC',
    'BONEFOS',
    'BONVIVA',
    'BORTEGA',
    'BORTEZOMIB ACCORD',
    'BORTEZOMIB ACTAVIS',
    'BORTEZOMIB ADAMED',
    'BORTEZOMIB EBEWE',
    'BORTEZOMIB GLENMARK',
    'BORTEZOMIB TEVA',
    'BOSENTAN ABDI',
    'BOSENTAN ACCORD',
    'BOSENTAN AVMC',
    'BOSENTAN EBEWE',
    'BOSENTAN MYLAN',
    'BOSENTAN PHARMASCIENCE INTERNATIONAL',
    'BOSENTAN WELDING',
    'BOTOX',
    'BRALTUS',
    'BRAMITOB',
    'BRAUNOVIDON',
    'BRAUNOVIDON GÁZA S MASTÍ',
    'BRAVELLE',
    'BREAKYL',
    'BRETARIS GENUAIR',
    'BRICANYL',
    'BRILIQUE',
    'BRIMICA GENUAIR',
    'BRIMONIDIN OLIKLA',
    'BRINTELLIX',
    'BRINZOLAMID APOTEX',
    'BRINZOLAMID MYLAN',
    'BRINZOLAMID OLIKLA',
    'BRINZOLAMIDE STADA',
    'BRIVIACT',
    'BRIZADOPT',
    'BRONCHITOL',
    'BRONCHO-VAXOM PRO ADULTIS',
    'BRONCHO-VAXOM PRO INFANTIBUS',
    'BRONCHO-VAXOM PRO INFANTIBUS SÁČKY',
    'BRUFEN',
    'BUCCOLAM',
    'BUDENOFALK',
    'BUDENOFALK UNO',
    'BUDIAIR',
    'BUFOMIX EASYHALER',
    'BUPRENORFIN MYLAN',
    'BUPRENORPHINE ACTAVIS',
    'BUPRENORPHINE SANDOZ',
    'BUPRETEC',
    'BURONIL',
    'BUSCOPAN',
    'BUSPIRON-EGIS',
    'BUVENTOL EASYHALER 100 MCG/DOSE',
    'BUVENTOL EASYHALER 200 MCG/DOSE',
    'BYDUREON',
    'BYETTA',
    'BYOL',
    'CABOMETYX',
    'CADUET',
    'CAELYX',
    'CALCICHEW D3 JAHODA',
    'CALCICHEW D3 LEMON',
    'CALCIFEROL BIOTIKA FORTE',
    'CALCII CARBONICI 0,5 TBL. MEDICAMENTA',
    'CALCIUM BIOTIKA',
    'CALCIUM CHLORATUM BIOTIKA',
    'CALCIUM CHOLECALCIFEROL BÉRES',
    'CALCIUM FOLINATE KABI',
    'CALCIUM FOLINATE SANDOZ',
    'CALCIUM FOLINATE TEVA 150 MG',
    'CALCIUM FOLINATE TEVA 450 MG',
    'CALCIUM GLUCONICUM 10% B.BRAUN',
    'CALCIUMFOLINAT "EBEWE"',
    'CALCIUM/VITAMIN D3 MEDA',
    'CALCIUM/VITAMIN D3 SANDOZ',
    'CALMOLAN',
    'CALTRATE D3',
    'CALTRATE 600 MG/400 IU D3 POTAHOVANÁ TABLETA',
    'CALYPSOL',
    'CAMPTO',
    'CANCIDAS',
    'CANCOMBINO',
    'CANDESARTAN/HYDROCHLOROTHIAZIDE SWYSSI',
    'CANDESARTAN/HYDROCHLOROTHIAZIDE TCHAIKAPHARMA',
    'CANDEZEK',
    'CANOCORD',
    'CANRI',
    'CAPECITABINE ACCORD',
    'CAPECITABINE FRESENIUS KABI',
    'CAPECITABINE GLENMARK',
    'CAPECITABINE MEDAC',
    'CAPECITABINE MYLAN',
    'CAPECITABINE PHARMAGEN',
    'CAPECITABINE TEVA',
    'CARAMLO',
    'CARBOPLATIN ACCORD',
    'CARBOPLATIN KABI',
    'CARDIKET RETARD',
    'CARDIKET RETARD 120',
    'CARDIKET RETARD 40',
    'CARDILAN',
    'CARDILOPIN',
    'CARDURA XL',
    'CARNITINE EASY LIQUID',
    'CARSAXA',
    'CARTEOL LP 2%',
    'CARVESAN 25',
    'CARVESAN 6,25',
    'CARZAP',
    'CARZAP HCT',
    'CASPOFUNGIN ACCORD',
    'CASPOFUNGIN TEVA',
    'CASTISPIR',
    'CAZACOMBI',
    'CAZAPROL',
    'CEENU LOMUSTINE (CCNU)',
    'CEFAZOLIN SANDOZ',
    'CEFIXIME INNFARM',
    'CEFOBID',
    'CEFOTAXIME LEK',
    'CEFTRIAXON KABI',
    'CEFTRIAXON MEDOPHARM',
    'CEFTRIAXON SANDOZ',
    'CEFZIL',
    'CEFZIL O.S.',
    'CELEBREX',
    'CELEKOXIB MYLAN',
    'CELIPROLOL VITABALANS',
    'CELLCEPT',
    'CELSENTRI',
    'CEPROTIN',
    'CERDELGA',
    'CEREZYME',
    'CERTICAN',
    'CERUCAL',
    'CERVARIX',
    'CETROTIDE',
    'CEZERA',
    'CHIROCAINE',
    'CHLORAMPHENICOL VUAB',
    'CHLORID SODNÝ 0,9% BRAUN',
    'CHLORPROTHIXEN 15 LÉČIVA',
    'CHLORPROTHIXEN 50 LÉČIVA',
    'CHOLESTEROL MODULE',
    'CHOLIB',
    'CICLOSPORIN MYLAN',
    'CIFLOXINAL',
    'CILOSTAZOL APOTEX',
    'CILOSTAZOL STADA',
    'CILOXAN',
    'CILOZEK',
    'CIMZIA',
    'CIPLOX',
    'CIPRALEX',
    'CIPRALEX OROTAB',
    'CIPRINOL',
    'CIPRINOL 100 MG/10 ML',
    'CIPRINOL 250',
    'CIPRINOL 500',
    'CISATRACURIUM KABI',
    'CISORDINOL',
    'CISORDINOL DEPOT',
    'CISORDINOL-ACUTARD',
    'CISPLATIN EBEWE',
    'CISPLATIN KABI',
    'CITALEC 10 ZENTIVA',
    'CITALEC 20 ZENTIVA',
    'CITALON',
    'CITALOPRAM ORION',
    'CITALOPRAM +PHARMA',
    'CITALOPRAM VITABALANS',
    'CITALOPRAM-TEVA',
    'CITRAFLEET',
    'CLARELUX',
    'CLARISCAN',
    'CLARITHROMYCIN TEVA',
    'CLARITINE',
    'CLAUDIENNE',
    'CLENSIA',
    'CLEXANE',
    'CLEXANE FORTE',
    'CLIMARA 50',
    'CLIMEN',
    'CLINDAMYCIN KABI',
    'CLOBEX',
    'CLOPIDOGREL ACCORD',
    'CLOPIDOGREL ACTAVIS',
    'CLOPIDOGREL AL',
    'CLOPIDOGREL APOTEX',
    'CLOPIDOGREL MYLAN',
    'CLOPIDOGREL STADA',
    'CLOPIDOGREL TEVA',
    'CLOSTILBEGYT',
    'CLOZAPIN DESITIN',
    'COAXIL',
    'CODEIN SLOVAKOFARMA',
    'COLCHICUM-DISPERT',
    'COLOBREATHE',
    'COLOMYCIN INJEKCE 1 000 000 MEZINÁRODNÍCH JEDNOTEK',
    'COLOXET',
    'COLTOWAN',
    'COMBAIR',
    'COMBIGAN',
    'COMBISO',
    'COMBIVIR',
    'COMIDA HCYS B FORMULA',
    'COMIDA HCYS C FORMULA',
    'COMIDA PKU A FORMULA',
    'COMIDA PKU B',
    'COMIDA PKU B FORMULA',
    'COMIDA PKU B FORMULA ČOKOLÁDA',
    'COMIDA PKU B FORMULA JAHODA',
    'COMIDA PKU B PIŇA COLADA',
    'COMIDA PKU C',
    'COMIDA PKU C FORMULA',
    'COMIDA PKU C FORMULA POMERANČ - CITRÓN',
    'COMIDA PKU C PIŇA COLADA',
    'COMIDAGEN',
    'COMIDAGEN PLUS',
    'COMPETACT',
    'COMTAN',
    'CONCERTA',
    'CONCOR COMBI',
    'CONCOR COR',
    'CONCOR 10',
    'CONCOR 5',
    'CONDROSULF',
    'CONISOR',
    'CONTROLOC',
    'CONTROLOC I.V.',
    'CONVERIDE',
    'CONVULEX',
    'CONVULEX CR',
    'COPAXONE',
    'COPEGUS',
    'CORBILTA',
    'CORDARONE',
    'CORDIPIN XL',
    'CORSIM',
    'CORTIMENT',
    'CORVAPRO',
    'CORVATON FORTE',
    'CORYOL',
    'COSENTYX',
    'COSOPT',
    'COSOPT FREE BEZ KONZERVAČNÍCH PŘÍSAD',
    'COSOPT FREE BEZ KONZERVAČNÍCH PŘÍSAD 20MG/ML+5MG/ML OČ.KAP.,ROZ.,J.OB.',
    'COSYREL',
    'COTELLIC',
    'COTRIMOXAZOL AL FORTE',
    'CRESTOR',
    'CRINONE',
    'CROMOHEXAL',
    'CUBISON',
    'CUBITAN S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'CUBITAN S PŘÍCHUTÍ JAHODOVOU',
    'CUBITAN S PŘÍCHUTÍ VANILKOVOU',
    'CURACNÉ',
    'CURATODERM',
    'CUVITRU',
    'CYMBALTA',
    'CYMEVENE',
    'CYNT 0,2',
    'CYNT 0,3',
    'CYNT 0,4',
    'CYRAMZA',
    'CYSAXAL',
    'CYTARABIN KABI',
    'CYTARABINE ACCORD',
    'DACARBAZINE MEDAC',
    'DACARBAZINE PHARMALEX',
    'DACEPTON',
    'DAFNEGIN',
    'DAIVOBET',
    'DAKLINZA',
    'DALACIN',
    'DALACIN C',
    'DAPRIL',
    'DARUNAVIR KRKA',
    'DARUNAVIR MYLAN',
    'DARUNAVIR SANDOZ',
    'DARUNAVIR TEVA',
    'DASSELTA',
    'DAXAS',
    'DECAPEPTYL',
    'DECAPEPTYL DEPOT',
    'DEGAN',
    'DEGAN 10 MG ROZTOK PRO INJEKCI',
    'DELESIT',
    'DELIPID',
    'DELIPID PLUS',
    'DENIBAN',
    'DEOXYMYKOIN',
    'DEPAKINE',
    'DEPAKINE CHRONO 300 MG SÉCABLE',
    'DEPAKINE CHRONO 500 MG SÉCABLE',
    'DEPO-MEDROL',
    'DEPRESINAL',
    'DEPREX LÉČIVA',
    'DERIN',
    'DERIN PROLONG',
    'DERMESTRIL 25',
    'DERMESTRIL 50',
    'DERMESTRIL-SEPTEM 25',
    'DERMESTRIL-SEPTEM 50',
    'DERMOVATE',
    'DESCOVY',
    'DESFERAL',
    'DESLORATADIN APOTEX',
    'DESLORATADIN MYLAN',
    'DESLORATADIN +PHARMA',
    'DESLORATADIN SANECA',
    'DESLORATADIN STADA',
    'DESLORATADIN XANTIS',
    'DESLORATADIN ZENTIVA',
    'DESLORATADINE ACTAVIS',
    'DESLORATADINE TEVA',
    'DETRALEX',
    'DETRUSITOL SR',
    'DEXAMED',
    'DEXAMETHASONE KRKA',
    'DEXAMETHASONE WZF POLFA',
    'DHC CONTINUS',
    'DIACOMIT',
    'DIACORDIN 120 RETARD',
    'DIACORDIN 240 SR',
    'DIACORDIN 60',
    'DIACORDIN 90 RETARD',
    'DIAPREL MR',
    'DIASIP S PŘÍCHUTÍ CAPPUCCINO',
    'DIASIP S PŘÍCHUTÍ JAHODOVOU',
    'DIASIP S PŘÍCHUTÍ VANILKOVOU',
    'DIAZEPAM DESITIN RECTAL TUBE',
    'DIAZEPAM SLOVAKOFARMA',
    'DIBEN',
    'DIBEN DRINK CAPPUCCINO',
    'DIBEN DRINK LESNÍ PLODY',
    'DIBEN DRINK VANILKA',
    'DIBEN 1,5 KCAL HP',
    'DIBETIX',
    'DICLOABAK',
    'DICLOFENAC AL RETARD',
    'DICLOFENAC AL 50',
    'DICLOFENAC DUO PHARMASWISS',
    'DICUNO',
    'DICYNONE 250',
    'DIFFERINE',
    'DIFLUCAN',
    'DIGOXIN LÉČIVA',
    'DILATREND 25',
    'DILATREND 6,25',
    'DILIZOLEN',
    'DILURAN',
    'DIMEXOL',
    'DIPHERELINE',
    'DIPHERELINE S.R.',
    'DIPIDOLOR',
    'DIPROSALIC',
    'DIPROSONE',
    'DIROTON',
    'DIROTON PLUS H',
    'DITHIADEN',
    'DITHIADEN INJ',
    'DITROPAN',
    'DIVINA',
    'DOBUJECT',
    'DOCETAXEL ACCORD',
    'DOCETAXEL ACTAVIS',
    'DOCETAXEL AMRING',
    'DOCETAXEL EBEWE',
    'DOCETAXEL KABI',
    'DOCETAXEL TEVA',
    'DOGMATIL',
    'DOLFORIN',
    'DOLGIT 800',
    'DOLMINA INJ',
    'DOLMINA 100 SR',
    'DOLMINA 50',
    'DOLOCODON',
    'DOLSIN',
    'DONA',
    'DONEPEZIL ACCORD',
    'DONEPEZIL ACTAVIS',
    'DONEPEZIL KRKA',
    'DONEPEZIL MYLAN',
    'DONEPEZIL SANDOZ',
    'DONEPEZIL SANDOZ 10 MG DISTAB',
    'DONPETHON',
    'DOPEGYT',
    'DORETA',
    'DORMICUM',
    'DORSIFLEX',
    'DORZOGEN COMBI',
    'DOSPELIN',
    'DOSTINEX',
    'DOTAREM',
    'DOXAZOSIN AUROBINDO',
    'DOXAZOSIN MYLAN',
    'DOXORUBICIN "EBEWE"',
    'DOXORUBICIN MEDAC',
    'DOXORUBICIN PHARMAGEN',
    'DOXORUBICIN TEVA',
    'DOXYBENE',
    'DOXYHEXAL',
    'DOZOTIMA',
    'DRACENAX',
    'DRETACEN',
    'DUAC',
    'DUAKLIR GENUAIR',
    'DUALKOPT',
    'DULASOLAN',
    'DULOXETIN MYLAN',
    'DULOXETIN +PHARMA',
    'DULSEVIA',
    'DUODART',
    'DUODOPA',
    'DUOPLAVIN',
    'DUORESP SPIROMAX',
    'DUOTRAV',
    'DUPHASTON',
    'DURACEF',
    'DUROGESIC',
    'DUSPATALIN RETARD',
    'DUTALAN',
    'DUTASTERIDE GALENICUM',
    'DYMISTIN',
    'DYNASTAT',
    'DYSPORT',
    'DYXAL',
    'EBIXA',
    'EBRANTIL I.V. 25',
    'EBRANTIL I.V. 50',
    'EBRANTIL 30 RETARD',
    'EBRANTIL 60 RETARD',
    'ECALTA',
    'ECANSYA',
    'ECOBEC',
    'ECOSAL INHALER',
    'EDICIN',
    'EDURANT',
    'EFAVIRENZ/ EMTRICITABINE/ TENOFOVIR DISOPROXIL TEVA',
    'EFAVIRENZ TEVA',
    'EFECTIN ER',
    'EFFENTORA',
    'EFFICIB',
    'EFFLUMIDEX LIQUIFILM',
    'EFIENT',
    'EFLORAN',
    'EGILOK',
    'EGILOK SUCC',
    'EGIRAMLON',
    'EGISTROZOL',
    'EGITIM',
    'EGITROMB',
    'EGLYMAD',
    'EGOLANZA',
    'EGZYSTA',
    'EIGENORM SET',
    'ELAPRASE',
    'ELEMENTAL 028 EXTRA',
    'ELENIUM',
    'ELICEA',
    'ELICEA ORO TAB',
    'ELIDEL',
    'ELIFY',
    'ELIGARD',
    'ELIQUIS',
    'ELOCOM',
    'ELOCTA',
    'ELONTRIL',
    'ELONVA',
    'EMADINE',
    'EMANERA',
    'EMEND',
    'EMERADE',
    'EMPLICITI',
    'EMSELEX',
    'EMTRICITABINE/TENOFOVIR DISOPROXIL KRKA',
    'EMTRICITABINE/TENOFOVIR DISOPROXIL MYLAN',
    'EMTRICITABINE/TENOFOVIR DISOPROXIL SANDOZ',
    'EMTRICITABINE/TENOFOVIR DISOPROXIL TEVA',
    'ENALAPRIL VITABALANS',
    'ENAP',
    'ENAP I.V.',
    'ENAP-H',
    'ENAP-HL',
    'ENAPRIL',
    'ENBREL',
    'ENBREL PRO PEDIATRICKÉ POUŽITÍ',
    'ENDOXAN',
    'ENELBIN 100 RETARD',
    'ENHANCIN',
    'ENSTILAR',
    'ENSURE PLUS ADVANCE BANÁNOVÁ PŘÍCHUŤ',
    'ENSURE PLUS ADVANCE ČOKOLÁDOVÁ PŘÍCHUŤ',
    'ENSURE PLUS ADVANCE JAHODOVÁ PŘÍCHUŤ',
    'ENSURE PLUS ADVANCE KÁVOVÁ PŘÍCHUŤ',
    'ENSURE PLUS ADVANCE PŘÍCHUŤ BANÁN',
    'ENSURE PLUS ADVANCE PŘÍCHUŤ ČOKOLÁDA',
    'ENSURE PLUS ADVANCE PŘÍCHUŤ VANILKA',
    'ENSURE PLUS ADVANCE RTH VANILKOVÁ PŘÍCHUŤ',
    'ENSURE PLUS ADVANCE VANILKOVÁ PŘÍCHUŤ',
    'ENSURE PLUS FIBER ČOKOLÁDOVÁ PŘÍCHUŤ',
    'ENSURE PLUS FIBER MALINOVÁ PŘÍCHUŤ',
    'ENSURE PLUS FIBER VANILKOVÁ PŘÍCHUŤ',
    'ENSURE PLUS PŘÍCHUŤ BANÁN',
    'ENSURE PLUS PŘÍCHUŤ BANÁNOVÁ',
    'ENSURE PLUS PŘÍCHUŤ ČOKOLÁDA',
    'ENSURE PLUS PŘÍCHUŤ JAHODA',
    'ENSURE PLUS PŘÍCHUŤ LESNÍ OVOCE',
    'ENSURE PLUS PŘÍCHUŤ VANILKA',
    'ENSURE TWOCAL PŘÍCHUŤ BANÁNOVÁ',
    'ENSURE TWOCAL PŘÍCHUŤ JAHODA',
    'ENSURE TWOCAL PŘÍCHUŤ NEUTRÁLNÍ',
    'ENSURE TWOCAL PŘÍCHUŤ VANILKA',
    'ENTECAVIR ACCORD',
    'ENTECAVIR AVMC',
    'ENTECAVIR GLENMARK',
    'ENTECAVIR MYLAN',
    'ENTECAVIR SANDOZ',
    'ENTIZOL',
    'ENTOCORT',
    'ENTOCORT KLYZMA',
    'ENTRESTO',
    'ENTYVIO',
    'ENVARSUS',
    'ENYGLID',
    'EPANUTIN PARENTERAL',
    'EPCLUSA',
    'EPILAN D GEROT',
    'EPILETAM',
    'EPIMEDAC',
    'EPIPEN',
    'EPIPEN JR.',
    'EPIRUBICIN ACCORD',
    'EPIRUBICIN ACTAVIS',
    'EPIVIR',
    'EPLERENON ACTAVIS',
    'EPLERENON SANDOZ',
    'EPORATIO',
    'EPREX',
    'EQUORAL',
    'ERBITUX',
    'ERDOMED',
    'ERSITTIN',
    'ESBRIET',
    'ESCEPRAN',
    'ESCIRDEC NEO',
    'ESCITALOPRAM ACCORD',
    'ESCITALOPRAM FARMAX',
    'ESCITALOPRAM MYLAN',
    'ESCITALOPRAM +PHARMA',
    'ESCITALOPRAM TEVA',
    'ESCITIL',
    'ESMOCARD',
    'ESMOCARD LYO',
    'ESMYA',
    'ESOPREX',
    'ESOPREX OROTAB',
    'ESPRITAL 30',
    'ESPRITAL 45',
    'ESTAN',
    'ESTRACYT',
    'ESTRAHEXAL 25',
    'ESTRAHEXAL 50',
    'ESTRIMAX',
    'ESTROFEM',
    'ETOPOSID "EBEWE"',
    'ETOPOSIDE ACCORD',
    'ETOPOSIDE TEVA',
    'ETRUZIL',
    'EUCREAS',
    'EUPHYLLIN CR N 100',
    'EUPHYLLIN CR N 200',
    'EUPHYLLIN CR N 300',
    'EUPHYLLIN CR N 400',
    'EUTHYROX',
    'EVIPLERA',
    'EVOTAZ',
    'EWOFEX',
    'EXACYL',
    'EXCIPIAL U HYDROLOTIO',
    'EXCIPIAL U LIPOLOTIO',
    'EXELON',
    'EXEMESTAN ACTAVIS',
    'EXEMESTAN MYLAN',
    'EXEMESTANE ACCORD',
    'EXJADE',
    'EXPLEMED',
    'EXPLEMED RAPID',
    'EXTAVIA',
    'EXVIERA',
    'EYLEA',
    'EZANTRIS',
    'EZEN',
    'EZETIMIB APOTEX',
    'EZETIMIB MYLAN',
    'EZETIMIB STADA',
    'EZETIMIB TEVA',
    'EZETIMIBE ACCORD',
    'EZETIMIBE ASTRON',
    'EZETIMIBE GLENMARK',
    'EZETROL',
    'EZICLEN',
    'EZOLETA',
    'FABRAZYME',
    'FACTOR VII BAXALTA',
    'FACTOR VII BAXTER',
    'FAMOSAN',
    'FANHDI',
    'FASLODEX',
    'FASTUM',
    'FEBIRA 200',
    'FEIBA NF',
    'FEMARA',
    'FEMOSTON CONTI',
    'FEMOSTON MINI',
    'FEMOSTON 1/10',
    'FEMOSTON 2/10',
    'FENOFIX',
    'FENTALIS',
    'FENTANYL KALCEKS',
    'FENTANYL MYLAN',
    'FENTANYL TORREX',
    'FENTANYL-RATIOPHARM',
    'FENTANYL-RATIOPHARM 12 MCG/H',
    'FERANT',
    'FERINJECT',
    'FERRETAB COMP.',
    'FERRIPROX',
    'FERRLECIT',
    'FERRO-FOLGAMMA',
    'FEVARIN',
    'FEXIGRA TABLETY',
    'FIASP',
    'FINAJELF',
    'FINANORM',
    'FINARD',
    'FINASTERID MYLAN',
    'FINEX',
    'FINPROS',
    'FIRAZYR',
    'FIRMAGON',
    'FLAMEXIN',
    'FLAREX',
    'FLASTIN',
    'FLEBOGAMMA DIF',
    'FLECTOR EP RAPID',
    'FLEXBUMIN',
    'FLEXOVE',
    'FLIXABI',
    'FLIXOTIDE DISKUS 100',
    'FLIXOTIDE DISKUS 250',
    'FLIXOTIDE DISKUS 500',
    'FLIXOTIDE 125 INHALER N',
    'FLIXOTIDE 250 INHALER N',
    'FLIXOTIDE 50 INHALER N',
    'FLOLAN',
    'FLONIDAN',
    'FLONIDAN DISTAB',
    'FLOXAL',
    'FLUANXOL',
    'FLUANXOL DEPOT',
    'FLUARIX TETRA',
    'FLUCINAR',
    'FLUCON',
    'FLUCONAZOL AUROBINDO',
    'FLUCONAZOL AUROBINDO 100 MG TVRDÉ TOBOLKY',
    'FLUCONAZOL KABI',
    'FLUCONAZOLE AUROVITAS',
    'FLUCONAZOLE B.BRAUN',
    'FLUDARA',
    'FLUDARABIN EBEWE',
    'FLUDARABINE ACCORD',
    'FLUDARABINE-TEVA',
    'FLUDROCORTISON',
    'FLUKONAZOL PMCS',
    'FLUKONAZOL SANDOZ',
    'FLUMAZENIL PHARMASELECT',
    'FLUORESCITE',
    'FLUOROURACIL ACCORD',
    'FLUOXETINE VITABALANS',
    'FLUTIFORM',
    'FLUTIFORM K-HALER',
    'FLUZAK',
    'FOKUSIN',
    'FOLINAR',
    'FOLIVIRIN',
    'FORADIL',
    'FORCAN - 150',
    'FOREXO',
    'FORMANO',
    'FORMOTEROL EASYHALER',
    'FORMOTEROL-RATIOPHARM',
    'FORMOVENT',
    'FORSTEO',
    'FORTECORTIN 4',
    'FORTICARE S PŘÍCHUTÍ BROSKEV A ZÁZVOR',
    'FORTICARE S PŘÍCHUTÍ CAPPUCCINO',
    'FORTICARE S PŘÍCHUTÍ POMERANČ A CITRÓN',
    'FORTILIP',
    'FORTINI CREAMY FRUIT MULTI FIBRE ČERVENÉ OVOCE',
    'FORTINI CREAMY FRUIT MULTI FIBRE LETNÍ OVOCE',
    'FORTINI PRO DĚTI S VLÁKNINOU - BANÁNOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU - ČOKOLÁDOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU - JAHODOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU - NEUTRAL',
    'FORTINI PRO DĚTI S VLÁKNINOU - VANILKOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU, BANÁNOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU, ČOKOLÁDOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU, JAHODOVÁ PŘÍCHUŤ',
    'FORTINI PRO DĚTI S VLÁKNINOU, NEUTRAL',
    'FORTINI PRO DĚTI S VLÁKNINOU, VANILKOVÁ PŘÍCHUŤ',
    'FORTRANS',
    'FORTUM',
    'FORXIGA',
    'FOSAVANCE',
    'FOSINOPRIL-TEVA',
    'FOSRENOL',
    'FOSTIMON',
    'FOTIL',
    'FOTIL FORTE',
    'FOXIS',
    'FRAMYKOIN',
    'FRAXIPARIN MULTI',
    'FRAXIPARINE',
    'FRAXIPARINE FORTE',
    'FRESUBIN ENERGY',
    'FRESUBIN ENERGY DRINK BANÁN',
    'FRESUBIN ENERGY DRINK CAPPUCCINO',
    'FRESUBIN ENERGY DRINK ČOKOLÁDA',
    'FRESUBIN ENERGY DRINK NEUTRAL',
    'FRESUBIN ENERGY FIBRE',
    'FRESUBIN ENERGY FIBRE BANÁN',
    'FRESUBIN ENERGY FIBRE CAPPUCCINO',
    'FRESUBIN ENERGY FIBRE CITRÓN',
    'FRESUBIN ENERGY FIBRE ČOKOLÁDA',
    'FRESUBIN ENERGY FIBRE JAHODA',
    'FRESUBIN ENERGY FIBRE VANILKA',
    'FRESUBIN ENERGY FIBRE VIŠEŇ',
    'FRESUBIN HP ENERGY',
    'FRESUBIN JUCY DRINK PŘÍCHUŤ ČERNÝ RYBÍZ',
    'FRESUBIN JUCY DRINK PŘÍCHUŤ JABLEČNÁ',
    'FRESUBIN JUCY DRINK PŘÍCHUŤ VIŠŇOVÁ',
    'FRESUBIN ORIGINAL FIBRE',
    'FRESUBIN ORIGINAL NEUTRAL',
    'FRESUBIN ORIGINAL S BROSKVOVOU PŘÍCHUTÍ',
    'FRESUBIN ORIGINAL S ČOKOLÁDOVOU PŘÍCHUTÍ',
    'FRESUBIN ORIGINAL S VANILKOVOU PŘÍCHUTÍ',
    'FRESUBIN PROTEIN ENERGY ČOKOLÁDA',
    'FRESUBIN PROTEIN ENERGY JAHODA',
    'FRESUBIN PROTEIN ENERGY OŘÍŠEK',
    'FRESUBIN PROTEIN ENERGY VANILKA',
    'FRESUBIN RENAL VANILKA',
    'FRESUBIN 2 KCAL CREME CAPPUCCINO',
    'FRESUBIN 2 KCAL CREME ČOKOLÁDA',
    'FRESUBIN 2 KCAL CREME LESNÍ JAHODA',
    'FRESUBIN 2 KCAL CREME VANILKA',
    'FRESUBIN 2 KCAL DRINK CAPPUCCINO',
    'FRESUBIN 2 KCAL DRINK KARAMEL',
    'FRESUBIN 2 KCAL DRINK LESNÍ PLODY',
    'FRESUBIN 2 KCAL DRINK NEUTRAL',
    'FRESUBIN 2 KCAL DRINK VANILKA',
    'FRESUBIN 2 KCAL HP FIBRE',
    'FRESUBIN 3,2 KCAL DRINK LÍSKOVÝ OŘÍŠEK',
    'FRESUBIN 3,2 KCAL DRINK MANGO',
    'FRESUBIN 3,2 KCAL DRINK VANILKA - KARAMEL',
    'FRISIUM',
    'FROMILID',
    'FROMILID UNO',
    'FROMILID 500',
    'FRONTIN',
    'FUCICORT',
    'FUCICORT LIPID',
    'FUCIDIN',
    'FUCIDIN H',
    'FUCITHALMIC',
    'FULLHALE',
    'FUROLIN',
    'FURON',
    'FURORESE 125',
    'FURORESE 20 INJEKT',
    'FURORESE 250',
    'FURORESE 40',
    'FURORESE 500',
    'FUROSEMID - SLOVAKOFARMA FORTE',
    'FUROSEMID ACCORD',
    'FUROSEMID BIOTIKA',
    'FUROSEMID BIOTIKA FORTE',
    'FUROSEMID KABI',
    'FYCOMPA',
    'FYZIOLOGICKÝ ROZTOK VIAFLO',
    'GA 1 ANAMIX INFANT',
    'GABAGAMMA',
    'GABANOX',
    'GABAPENTIN AUROBINDO',
    'GABAPENTIN AUROVITAS',
    'GABAPENTIN TEVA',
    'GABITRIL',
    'GADOVIST',
    'GALACTOMIN 17',
    'GALAFOLD',
    'GALANTAMIN MYLAN',
    'GALVUS',
    'GAMMAGARD S/D',
    'GAMMANORM',
    'GAMUNEX',
    'GANFORT',
    'GARAMYCIN SCHWAMM',
    'GARDASIL',
    'GARDASIL 9',
    'GAZYVARO',
    'GECROL',
    'GEFIN',
    'GELARGIN',
    'GELASPAN 4%',
    'GELOFUSINE',
    'GEMCIRENA',
    'GEMCITABIN EBEWE',
    'GEMCITABINE ACCORD',
    'GEMCITABINE KABI',
    'GENOTROPIN',
    'GENTAMICIN LEK',
    'GENTAMICIN WZF POLFA',
    'GENVOYA',
    'GEROUSIA',
    'GILENYA',
    'GIONA EASYHALER 100',
    'GIONA EASYHALER 200',
    'GIONA EASYHALER 400',
    'GIOTRIF',
    'GLEPARK',
    'GLEPERIL',
    'GLEPERIL COMBI',
    'GLIBOMET',
    'GLICLAZID MYLAN',
    'GLIKLAZID ACTAVIS',
    'GLIMEPIRID MYLAN',
    'GLIMEPIRID SANDOZ',
    'GLIMEPIRID-RATIOPHARM',
    'GLIVEC',
    'GLUCAGEN 1 MG HYPOKIT',
    'GLUCERNA SELECT VANILKOVÁ PŘÍCHUŤ',
    'GLUCERNA SR ČOKOLÁDOVÁ PŘÍCHUŤ',
    'GLUCERNA SR JAHODOVÁ PŘÍCHUŤ',
    'GLUCERNA SR PŘÍCHUŤ JAHODA',
    'GLUCERNA SR PŘÍCHUŤ VANILKA',
    'GLUCERNA SR VANILKOVÁ PŘÍCHUŤ',
    'GLUCOBAY 100',
    'GLUCOPHAGE',
    'GLUCOPHAGE XR',
    'GLUCOSE FRESENIUS KABI 10%',
    'GLUCOSE FRESENIUS KABI 20%',
    'GLUCOSE FRESENIUS KABI 5%',
    'GLUCOSE/SODIUM CHLORIDE FRESENIUS KABI 2,5%/0,45%',
    'GLUKÓZA 40 BRAUN',
    'GLUKÓZA 5 BRAUN',
    'GLUKÓZA 5% VIAFLO',
    'GLURENORM',
    'GLYCLADA',
    'GLYMEXAN',
    'GODASAL 100',
    'GONAL-F 300 IU/0,5 ML (22 MIKROGRAMŮ/0,5 ML)',
    'GONAL-F 450 IU/0,75 ML (33 MIKROGRAMŮ/0,75 ML)',
    'GONAL-F 75 IU (5,5 MIKROGRAMŮ)',
    'GONAL-F 900 IU/1,5 ML (66 MIKROGRAMŮ/1,5 ML)',
    'GOPTEN',
    'GORDIUS',
    'GRAMPOSIMIDE',
    'GRANEGIS',
    'GRANISETRON B. BRAUN',
    'GRANISETRON KABI',
    'GRANISETRON MYLAN',
    'GRANPIDAM',
    'GRATEVA',
    'GRAZAX',
    'GRIMODIN',
    'GTO-OIL',
    'GUAJACURAN',
    'GYNIPRAL',
    'GYNOVEL',
    'GYNPRODYL',
    'GYRABLOCK 400',
    'HAEMATE P',
    'HAEMOCOMPLETTAN P',
    'HAEMOCTIN SDH 1000',
    'HAEMOCTIN SDH 500',
    'HALAVEN',
    'HALOPERIDOL DECANOAT-RICHTER',
    'HALOPERIDOL-RICHTER',
    'HARTMANN´S SOLUTION BP "FRESENIUS"',
    'HARTMANN´S SOLUTION FRESENIUS KABI',
    'HARTMANNUV ROZTOK BRAUN BP',
    'HARTMANNŮV ROZTOK VIAFLO',
    'HARVONI',
    'HCU ANAMIX INFANT',
    'HEDONIN',
    'HEDONIN PROLONG',
    'HELEX RETARD',
    'HELICID',
    'HELICID 20 ZENTIVA',
    'HELICID 40 INF',
    'HELICOBACTER TEST INFAI',
    'HELIDES',
    'HELIXATE NEXGEN',
    'HEMANGIOL',
    'HEPARIN LÉČIVA',
    'HEPSERA',
    'HERCEPTIN',
    'HERPESIN 200',
    'HERPESIN 250',
    'HERPESIN 400',
    'HERZUMA',
    'HEXVIX',
    'HIBERIX',
    'HIPRES',
    'HIZENTRA',
    'HOLMEVIS',
    'HOLOXAN',
    'HOTEMIN',
    'HULIO',
    'HUMALOG',
    'HUMALOG JUNIOR KWIKPEN',
    'HUMALOG KWIKPEN',
    'HUMALOG MIX25',
    'HUMALOG MIX25 KWIKPEN',
    'HUMALOG MIX50',
    'HUMALOG MIX50 KWIKPEN',
    'HUMAN ALBUMIN BAXALTA',
    'HUMAN ALBUMIN CSL BEHRING',
    'HUMAN ALBUMIN GRIFOLS 20%',
    'HUMAN ALBUMIN GRIFOLS 5%',
    'HUMAN ALBUMIN 200 G/L BAXTER',
    'HUMATROPE',
    'HUMIRA',
    'HUMIRA K PEDIATRICKÉMU UŽITÍ',
    'HUMULIN M3 (30/70) CARTRIDGE',
    'HUMULIN M3 (30/70) KWIKPEN',
    'HUMULIN N (NPH) CARTRIDGE',
    'HUMULIN N (NPH) KWIKPEN',
    'HUMULIN R CARTRIDGE',
    'HUMULIN R KWIKPEN',
    'HYALGAN',
    'HYCAMTIN',
    'HYDROCHLOROTHIAZID LÉČIVA',
    'HYDROCHLOROTHIAZID TAINEX',
    'HYDROCORTISON VALEANT',
    'HYDROCORTISON VUAB',
    'HYDROCORTISON 10 MG JENAPHARM',
    'HYDROCORTISONE PHARMASWISS',
    'HYDROGENUHLIČITAN SODNÝ 4,2%(W/V)-BRAUN',
    'HYDROGENUHLIČITAN SODNÝ 8,4%(W/V)-BRAUN',
    'HYPLAFIN',
    'HYPNOMIDATE',
    'HYPOTYLIN',
    'HYQVIA',
    'HYRIMOZ',
    'HYTRIN',
    'IALUGEN PLUS',
    'IASIBON',
    'IBALGIN 600',
    'IBANDRONAT APOTEX',
    'IBANDRONÁT MYLAN',
    'IBANDRONIC ACID ACCORD',
    'IBANDRONIC ACID ACTAVIS',
    'IBANDRONIC ACID AUROBINDO',
    'IBANDRONIC ACID AUROVITAS',
    'IBANDRONIC ACID SANDOZ',
    'IBANDRONIC ACID STADA',
    'IBANDRONIC ACID TEVA',
    'IBUMAX',
    'ICLUSIG',
    'ICTADY',
    'IDARUBICIN ACCORD',
    'IFIRMACOMBI',
    'IFIRMASTA',
    'IGAMAD',
    'IGAMPLIA',
    'IKAMETIN',
    'ILARIS',
    'ILV-AM 2',
    'ILV-AM 3',
    'IMACORT',
    'IMASUP',
    'IMATINIB ACCORD',
    'IMATINIB GLENMARK',
    'IMATINIB KOANAA',
    'IMATINIB MYLAN',
    'IMATINIB PHARMAGEN',
    'IMATINIB SANDOZ',
    'IMATINIB STADA',
    'IMATINIB TEVA PHARMA',
    'IMBRUVICA',
    'IMIGRAN',
    'IMIPENEM/CILASTATIN KABI',
    'IMMUNATE STIM PLUS 1000 IU FVIII/750 IU VWF',
    'IMMUNATE STIM PLUS 500 IU FVIII/375 IU VWF',
    'IMMUNINE',
    'IMMUNINE BAXTER',
    'IMNOVID',
    'IMPACT ORAL KÁVOVÝ',
    'IMPACT ORAL TROPICKÉ OVOCE',
    'IMPLICOR',
    'IMRALDI',
    'IMTV-AM 2',
    'IMTV-AM 3',
    'IMUNOR',
    'IMURAN',
    'INCRELEX',
    'INCRESYNC',
    'INCRUSE ELLIPTA',
    'INDAP',
    'INDAPAMID PMCS',
    'INDAPAMID STADA',
    'INDAPAMIDE ORION',
    'INDIVINA',
    'INDOCOLLYRE',
    'INDOMETACIN BERLIN-CHEMIE',
    'INEGY',
    'INFALIN DUO',
    'INFASOURCE',
    'INFATRINI',
    'INFATRINI PEPTISORB',
    'INFECTOSCAB 5% KRÉM',
    'INFLECTRA',
    'INFLUVAC',
    'INFLUVAC TETRA',
    'INFUSIO NATRII CHLORATI ISOTONICA MEDIEKOS F 1/1',
    'INFUSIO RINGERI MEDIEKOS R1/1',
    'INHIBACE',
    'INHIBACE PLUS',
    'INJECTIO PROCAINII CHLORATI ARDEAPHARMA',
    'INJECTIO TRIMECAINII CHLORATI ARDEAPHARMA',
    'INJEXATE',
    'INLYTA',
    'INOVELON',
    'INSPRA',
    'INSTANYL',
    'INSULATARD PENFILL',
    'INSUMAN BASAL',
    'INSUMAN BASAL SOLOSTAR',
    'INSUMAN COMB 25',
    'INSUMAN COMB 25 SOLOSTAR',
    'INSUMAN RAPID',
    'INSUMAN RAPID SOLOSTAR',
    'INTELENCE',
    'INTRONA',
    'INVANZ',
    'INVEGA',
    'INVOKANA',
    'IOMERON 250',
    'IOMERON 300',
    'IOMERON 350',
    'IOMERON 400',
    'IPERTROFAN 40',
    'IRBESARTAN ACTAVIS',
    'IRBESARTAN HYDROCHLOROTHIAZIDE ZENTIVA',
    'IRBESARTAN MYLAN',
    'IRBESARTAN ZENTIVA',
    'IRBESARTAN/HYDROCHLOROTHIAZID MYLAN',
    'IRESSA',
    'IRINOTECAN ACCORDPHARMA',
    'IRINOTECAN FRESENIUS',
    'IRINOTECAN KABI',
    'IRINOTECAN MEDAC',
    'IRINOTECAN MYLAN',
    'IRINOTECAN PHARMAGEN',
    'IRUXOL MONO',
    'ISAME',
    'ISENTRESS',
    'ISICOM',
    'ISICOM 100 MG',
    'ISICOM 250 MG',
    'ISOKET SPRAY',
    'ISOLYTE',
    'ISOPRINOSINE',
    'ISOPTIN',
    'ISOPTIN SR',
    'ISOSOURCE ENERGY FIBRE NEUTRÁLNÍ',
    'ISOSOURCE ENERGY NEUTRÁLNÍ',
    'ISOSOURCE STANDARD BALANCE NEUTRÁLNÍ',
    'ISOSOURCE STANDARD FIBRE NEUTRÁLNÍ',
    'ISOSOURCE STANDARD NEUTRÁLNÍ',
    'ISOSOURCE 2,0 FIBRE',
    'ISOTRETINOIN BELUPO',
    'ITAKEM',
    'ITOPRID PMCS',
    'ITOPRID XANTIS',
    'ITRACONAZOL UNIVERSAL FARMA',
    'IVA ANAMIX INFANT',
    'IVABRADIN SANDOZ',
    'IVABRADIN TEVA',
    'IVABRADIN ZENTIVA',
    'IVABRADINE ACCORD',
    'IVABRADINE GLENMARK',
    'IZBA',
    'JANUMET',
    'JANUVIA',
    'JARDIANCE',
    'JAVLOR',
    'JENTADUETO',
    'JETREA',
    'JEVITY',
    'JEVITY PLUS HP',
    'JEVTANA',
    'JODID DRASELNÝ 2% UNIMED PHARMA',
    'JODID DRASELNÝ+SODNÝ 2% UNIM.PH',
    'JODID 100',
    'JOVESTO',
    'JUMEX',
    'KADCYLA',
    'KALETRA',
    'KALIUM CHLORATUM BIOMEDICA',
    'KALIUM CHLORATUM LÉČIVA 7,5%',
    'KALNORMIN',
    'KALVENOX',
    'KALYDECO',
    'KAMIREN 2',
    'KAMIREN 4',
    'KANAVIT',
    'KANILAD',
    'KANJINTI',
    'KAPIDIN',
    'KAPIDOKOR',
    'KARDEGIC',
    'KARTESADA',
    'KEMADRIN',
    'KEPPRA',
    'KETILEPT',
    'KETILEPT PROLONG',
    'KETOCAL',
    'KETOCAL VANILKA',
    'KETOCAL 4:1 BEZ PŘÍCHUTĚ',
    'KETOCAL 4:1 S PŘÍCHUTÍ VANILKOVOU',
    'KETONAL',
    'KETONAL FORTE',
    'KETONAL RETARD',
    'KETOSTERIL',
    'KETOTIFEN AL',
    'KEVZARA',
    'KEYTRUDA',
    'KINITO',
    'KIOVIG',
    'KIVEXA',
    'KLABAX',
    'KLACID',
    'KLACID SR',
    'KLACID 500',
    'KLIANE',
    'KLIMICIN',
    'KLIMODIEN',
    'KLIMONORM',
    'KLIOGEST',
    'KLION-D 100',
    'KOGENATE BAYER',
    'KOGNEZIL',
    'KOMBOGLYZE',
    'KORNAM',
    'KOVALTRY',
    'KREON 10 000',
    'KREON 25 000',
    'KREON 40 000',
    'KUVAN',
    'KVENTIAX',
    'KVENTIAX PROLONG',
    'KYBERNIN P',
    'KYLOTAN NEO',
    'KYLOTAN PLUS H',
    'KYNTHEUM',
    'KYPROLIS',
    'KYSELINA ACETYLSALICYLOVÁ XANTIS',
    'KYTRIL',
    'KYTRIL INJ/INF',
    'LACIPIL',
    'LACOSAMIDE ACCORD',
    'LACOSAMIDE GLENMARK',
    'LADYBON',
    'LAKEA',
    'LAMICTAL',
    'LAMISIL',
    'LAMIVUDIN TEVA',
    'LAMIVUDIN/ZIDOVUDIN MYLAN',
    'LAMOTRIGIN ACTAVIS',
    'LAMOTRIGIN AUROBINDO',
    'LAMOTRIGIN AUROVITAS',
    'LAMOTRIGIN MYLAN',
    'LAMOTRIGINE FARMAX',
    'LAMOTRIX',
    'LANBICA',
    'LANDEX',
    'LANNATAM',
    'LANSOPRAZOL LICONSA',
    'LANSOPRAZOL MOMAJA',
    'LANSOPRAZOL MYLAN',
    'LANTUS',
    'LANTUS SOLOSTAR',
    'LANVIS',
    'LANXIB',
    'LANZUL',
    'L-ARGININ',
    'L-ARGININ EASY',
    'L-ARGININE',
    'LATALUX',
    'LATANOPROST ACTAVIS',
    'LATANOPROST STADA',
    'LATANOPROST/TIMOLOL APOTEX',
    'LATIB',
    'LAZUREX',
    'L-CARNITINE',
    'L-CITRULIN',
    'L-CITRULIN EASY',
    'L-CITRULLINE',
    'L-CYSTIN',
    'LEDUFAN',
    'LEFLON',
    'LEFLUGEN',
    'LEFLUNOMID APOTEX',
    'LEFLUNOMID SANDOZ',
    'LEFLUNOMIDE MEDAC',
    'LEFLUNOPHARM',
    'LEKOPTIN',
    'LEKOPTIN RETARD',
    'LEMILVO',
    'LEMTRADA',
    'LENUXIN',
    'LENVIMA',
    'LENZETTO',
    'LEPONEX',
    'LEPTOPROL',
    'LERANA',
    'LERCANIDIPIN ORION',
    'LERIVON',
    'LERPIN 10',
    'LERPIN 20',
    'LESCOL XL',
    'LETMYLAN',
    'LETROVENA',
    'LETROX',
    'LETROZOL APOTEX',
    'LETROZOL TEVA PHARMA',
    'LEUKERAN',
    'LEVACT',
    'LEVELANZ',
    'LEVEMIR FLEXPEN',
    'LEVEMIR PENFILL',
    'LEVETIRACETAM ACCORD',
    'LEVETIRACETAM ACTAVIS',
    'LEVETIRACETAM G.L. PHARMA',
    'LEVETIRACETAM STADA',
    'LEVETIRACETAM TEVA',
    'LEVETIRACETAM UCB',
    'LEVOCETIRIZIN DR.MAX',
    'LEVODOPA/CARBIDOPA MYLAN',
    'LEVODOPA/CARBIDOPA/ENTACAPONE MYLAN',
    'LEVOFLOXACIN KABI',
    'LEVOFLOXACIN MYLAN',
    'LHRH FERRING',
    'LICOBONDRAT',
    'LIDOCAIN EGIS 10 %',
    'LIDOCAIN 10 %',
    'LIDOCAINE EGIS',
    'LIKARDA',
    'LINEZA',
    'LINEZOLID  ACCORD',
    'LINEZOLID ACCORD',
    'LINEZOLID KABI',
    'LINEZOLID KRKA',
    'LINEZOLID MYLAN',
    'LINEZOLID SANDOZ',
    'LINOLA FETT ÖLBAD',
    'LINOLADIOL N',
    'LINOLA-FETT',
    'LIPANOR',
    'LIPANTHYL NT',
    'LIPANTHYL S',
    'LIPANTHYL SUPRA',
    'LIPANTHYL 267 M',
    'LIPERTANCE',
    'LIPFIX',
    'LIPIODOL ULTRA-FLUIDE',
    'LIPOBASE',
    'LISIPRIL',
    'LISKANTIN',
    'L-ISOLEUCIN',
    'LITAK',
    'LITALIR',
    'LITHIUM CARBONICUM SLOVAKOFARMA',
    'LIVIAL',
    'L-KARNITIN',
    'LOCERYL',
    'LOCOID CRELO 0,1%',
    'LOCOID LIPOCREAM 0,1%',
    'LOCOID 0,1%',
    'LOCOID 0,1% LOTION',
    'LODOZ',
    'LODRONAT',
    'LOKREN',
    'LOMAC',
    'LOMEXIN',
    'LONQUEX',
    'LONSURF',
    'LOPHLEX',
    'LOPHLEX - LESNÍ OVOCE',
    'LOPHLEX - POMERANČ',
    'LOPHLEX LQ - CITRUS',
    'LOPHLEX LQ - LESNÍ OVOCE',
    'LOPHLEX LQ - POMERANČ',
    'LOPHLEX LQ - TROPICKÉ OVOCE',
    'LOPRIDAM',
    'LORADUR',
    'LORADUR MITE',
    'LORATADIN RATIOPHARM',
    'LORENZO - OIL',
    'LORINESPES',
    'LORISTA H',
    'LORISTA 100',
    'LORISTA 25',
    'LORISTA 50',
    'LOSAGEN',
    'LOSARTAN JS PARTNER',
    'LOSARTAN KRKA',
    'LOSARTAN ORION',
    'LOSARTAN STADA',
    'LOSARTAN TEVA',
    'LOSARTAN/HYDROCHLOROTHIAZID JS PARTNER',
    'LOSARTAN/HYDROCHLOROTHIAZID KRKA',
    'LOSEPRAZOL',
    'LOTERA',
    'LOZAP H',
    'LOZAP 100 ZENTIVA',
    'LOZAP 12,5 ZENTIVA',
    'LOZAP 50 ZENTIVA',
    'LT-AM 2',
    'LUCENTIS',
    'LUDIOMIL 25',
    'LUDIOMIL 75',
    'LUIVAC',
    'LUMIGAN',
    'LUMINAL',
    'LUNALDIN',
    'LUSOPRESS',
    'LUTINUS',
    'LUTRATE DEPOT',
    'LUVERIS',
    'LUXFEN',
    'L-VALIN',
    'LYNPARZA',
    'LYRICA',
    'LYSODREN',
    'LYXUMIA',
    'MABRON',
    'MABRON RETARD',
    'MABTHERA',
    'MACMIROR',
    'MACMIROR COMPLEX',
    'MACMIROR COMPLEX 500',
    'MACUGEN',
    'MADOPAR 250',
    'MAGNEROT',
    'MAGNESIUM SULFURICUM BBP',
    'MAGNESIUM SULFURICUM BIOTIKA',
    'MAGNOSOLV',
    'MAGRILAN',
    'MALTOFER',
    'MALTOFER TABLETY',
    'M-AM 2',
    'M-AM 3',
    'MANINIL',
    'MANNITOL FRESENIUS KABI 20%',
    'MANOASS',
    'MANTOMED',
    'MARATIA',
    'MARCAINE SPINAL',
    'MARCAINE SPINAL HEAVY',
    'MARCAINE 0,5%',
    'MARIXINO',
    'MATEVER',
    'MATRIFEN',
    'MAVENCLAD',
    'MAVIRET',
    'MAXIPIME',
    'MAXITROL',
    'MCP HEXAL 10',
    'MCT-OIL',
    'MEAXIN',
    'MEDOCLAV',
    'MEDOCRIPTINE',
    'MEDOPEXOL',
    'MEDOPIRID',
    'MEDORAM',
    'MEDORAM PLUS H',
    'MEDORISPER',
    'MEDOXIN',
    'MEDRACET',
    'MEDROL',
    'MEGACE',
    'MEGAMOX 1 G',
    'MEGAPLEX',
    'MEKINIST',
    'MELENOR',
    'MELOCOX',
    'MELOXICAM MYLAN',
    'MELOXICAM TEVA',
    'MEMABIX',
    'MEMANTIN APOTEX',
    'MEMANTIN MYLAN',
    'MEMANTIN SANDOZ',
    'MEMANTIN STADA',
    'MEMANTINE ACCORD',
    'MEMANTINE RATIOPHARM',
    'MEMANTINE VIPHARM',
    'MEMIGMIN',
    'MEMIXA',
    'MEMOLAN',
    'MENOPUR',
    'MENVEO',
    'MEPIVASTESIN',
    'MERONEM',
    'MEROPENEM BRADEX',
    'MEROPENEM KABI',
    'MEROPENEM ZENTIVA',
    'MERTENIL',
    'MESOCAIN 1%',
    'MESTINON',
    'MESULID',
    'METALCAPTASE',
    'METAMIZOL STADA',
    'METAMIZOLE KALCEKS',
    'METFOGAMMA',
    'METFOGAMMA 500',
    'METFOGAMMA 850',
    'METFORMIN MYLAN',
    'METFORMIN SANDOZ',
    'METFORMIN TEVA',
    'METFORMIN TEVA XR',
    'METFORMIN ZENTIVA',
    'METHOTREXAT ACCORD',
    'METHOTREXAT EBEWE',
    'METOJECT',
    'METOJECT PEN',
    'METOPROLOL AL 100',
    'METOPROLOL AL 200 RETARD',
    'METOPROLOL MYLAN',
    'METRONIDAZOL B. BRAUN',
    'METRONIDAZOLE 0,5%-POLPHARMA',
    'METVIX',
    'METYPRED',
    'MICARDIS',
    'MICARDISPLUS',
    'MICETAL',
    'MICROPAQUE',
    'MICROPAQUE CT',
    'MICTONETTEN',
    'MICTONORM',
    'MICTONORM UNO',
    'MIDAZOLAM ACCORD',
    'MIDAZOLAM B. BRAUN',
    'MIDAZOLAM TORREX',
    'MIDZA',
    'MIFLONID BREEZHALER',
    'MIFLONID 200',
    'MIFLONID 400',
    'MIGLUSTAT ACCORD',
    'MILUPA BASIC-CH',
    'MILUPA BASIC-F',
    'MILUPA BASIC-P',
    'MILUPA GA 2',
    'MILUPA GA 2 PRIMA',
    'MILUPA HOM 2',
    'MILUPA HOM 2 PRIMA',
    'MILUPA HOM 2 SECUNDA',
    'MILUPA HOM 2 SECUNDA 1X500 G',
    'MILUPA HOM 3 ADVANTA',
    'MILUPA LEU 2 PRIMA',
    'MILUPA LYS 1',
    'MILUPA LYS 2',
    'MILUPA MSUD 1',
    'MILUPA MSUD 2 PRIMA',
    'MILUPA MSUD 2 SECUNDA',
    'MILUPA MSUD 2 SECUNDA 1X500 G',
    'MILUPA MSUD 3 ADVANTA',
    'MILUPA OS 2 PRIMA',
    'MILUPA OS 2 SECUNDA',
    'MILUPA OS 2 SECUNDA 1X500 G',
    'MILUPA OS 3 ADVANTA',
    'MILUPA PKU 1 MIX',
    'MILUPA PKU 2 ACTIVA RAJČATOVÁ',
    'MILUPA PKU 2 FRUTA EXOTIC FRUITS',
    'MILUPA PKU 2 FRUTA RED FRUITS',
    'MILUPA PKU 2 MIX',
    'MILUPA PKU 2 PRIMA',
    'MILUPA PKU 2 SECUNDA 1X500 G',
    'MILUPA PKU 2 SHAKE ČOKOLÁDA',
    'MILUPA PKU 2 SHAKE JAHODA',
    'MILUPA PKU 3 - TEMPORA',
    'MILUPA PKU 3 ACTIVA RAJČATOVÁ',
    'MILUPA PKU 3 ADVANTA',
    'MILUPA PKU 3 SHAKE KAKAO',
    'MILUPA PKU 3 SHAKE MOCCA',
    'MILUPA TYR 1',
    'MILUPA TYR 2',
    'MILUPA TYR 2 PRIMA',
    'MILUPA TYR 2 SECUNDA',
    'MILUPA TYR 3 ADVANTA',
    'MILUPA UCD 2',
    'MILUPA UCD 2 PRIMA',
    'MILUPA UCD 2 SECUNDA',
    'MILUPA UCD 3 ADVANTA',
    'MILURIT',
    'MILURIT 100',
    'MIMPARA',
    'MINIDIAB',
    'MINIRIN',
    'MINIRIN MELT',
    'MIRAKLIDE',
    'MIRALUST',
    'MIRAPEXIN',
    'MIRCERA',
    'MIRTAZAPIN MYLAN',
    'MIRTAZAPIN ORION',
    'MIRTAZAPIN +PHARMA',
    'MIRTAZAPIN SANDOZ',
    'MIRZATEN',
    'MIRZATEN ORO TAB',
    'MITOMYCIN ACCORD',
    'MITOMYCIN C KYOWA',
    'MIVACRON',
    'MIXTARD 30 PENFILL',
    'MMA PA ANAMIX INFANT',
    'MODIGRAF',
    'MODULEN IBD 1X400 G',
    'MODURETIC',
    'MOLSIHEXAL RETARD',
    'MOMESALIC',
    'MOMETASON FUROÁT ACTAVIS',
    'MOMETASON FUROÁT CIPLA',
    'MOMMOX',
    'MONACE COMBI',
    'MONKASTA',
    'MONO MACK DEPOT',
    'MONONINE',
    'MONOPOST',
    'MONOPRIL',
    'MONOSAN',
    'MONOTAB SR',
    'MONOTAB 20',
    'MONTELAR',
    'MONTELUKAST ACTAVIS',
    'MONTELUKAST FARMAX',
    'MONTELUKAST MYLAN',
    'MONTELUKAST STADA',
    'MONTELUKAST TEVA',
    'MORPHIN BIOTIKA 1%',
    'MORPHINE KALCEKS',
    'MORYSA',
    'MOVIPREP',
    'MOXIFLOXACIN OLIKLA',
    'MOXOGAMMA',
    'MOXONIDIN ACTAVIS',
    'MOXONIDIN MYLAN',
    'MOXONIDIN SANECA',
    'MOXOSTAD',
    'MOXTENZ',
    'MOZOBIL',
    'MSUD ANAMIX INFANT',
    'MULTAQ',
    'MULTIHANCE',
    'MUSCARISAN',
    'MUSCORIL CPS',
    'MUSCORIL INJ',
    'MUSTOPHORAN',
    'MUTAFLOR',
    'MYCAMINE',
    'MYCLAUSEN',
    'MYCOBUTIN 150',
    'MYCOMAX',
    'MYCOPHENOLAT MOFETIL SANDOZ',
    'MYCOPHENOLIC ACID ACCORD',
    'MYDOCALM',
    'MYFENAX',
    'MYFORTIC',
    'MYLERAN',
    'MYOCET',
    'MYOZYME',
    'MYSILDECARD',
    'MYTELASE',
    'NAC AL 600 ŠUMIVÉ TABLETY',
    'NAKOM',
    'NAKOM MITE',
    'NALBUPHIN ORPHA',
    'NALCROM',
    'NALGESIN',
    'NALOXONE WZF POLFA',
    'NAPROXEN ARDEZ',
    'NARAMIG',
    'NASOBEC',
    'NASOFAN',
    'NASONEX',
    'NATRIUM CHLORATUM BIOTIKA SOLUTIO ISOTONICA',
    'NATRIUM CHLORATUM BIOTIKA 10%',
    'NATRIUM SALICYLICUM BIOTIKA',
    'NAVELBINE',
    'NAVELBINE ORAL',
    'NAVIREL',
    'NAXALGAN',
    'NAXYL',
    'NEBIDO',
    'NEBILET',
    'NEBIVOLOL SANDOZ',
    'NEMDATINE',
    'NEOCATE',
    'NEOCATE ADVANCE',
    'NEOCATE INFANT',
    'NEOCATE JUNIOR BEZ PŘÍCHUTĚ',
    'NEOCATE SYNEO',
    'NEOCLARITYN',
    'NEOFOLLIN',
    'NEO-GILURYTMAL',
    'NEORECORMON',
    'NEORECORMON VÍCEDÁVKOVÝ',
    'NEOSYNEPHRIN-POS 10%',
    'NEOTIGASON',
    'NEPRO HP PŘÍCHUŤ JAHODOVÁ',
    'NEPRO HP PŘÍCHUŤ VANILKOVÁ',
    'NESTLÉ ALTHÉRA',
    'NEULASTA',
    'NEUPRO',
    'NEUROBLOC',
    'NEUROL',
    'NEURONTIN',
    'NEUROTOP',
    'NEUROTOP RETARD 300',
    'NEUROTOP RETARD 600',
    'NEXAVAR',
    'NIDRAZID',
    'NIMBEX',
    'NIMED',
    'NIMENRIX',
    'NIMESIL',
    'NIMOTOP S',
    'NIMVASTID',
    'NITISINONE MENDELIKABS',
    'NITRENDIPIN-RATIOPHARM',
    'NITRESAN',
    'NITROGLYCERIN-SLOVAKOFARMA',
    'NITROMINT',
    'NIVESTIM',
    'NKH ANAMIX INFANT',
    'NOCDURNA',
    'NOCLAUD',
    'NOFLUX',
    'NOLICIN',
    'NOLPAZA',
    'NOPREX',
    'NORADRENALIN LÉČIVA',
    'NORDIMET',
    'NORDITROPIN SIMPLEXX',
    'NORESMEA',
    'NORETHISTERON ZENTIVA',
    'NORMAGLYC',
    'NORMIX',
    'NORPROLAC',
    'NORPROLAC 25/50 MCG TABLETY',
    'NORTRILEN',
    'NORVIR',
    'NO-SPA',
    'NOVALGIN',
    'NOVASOURCE DIABET NEUTRÁLNÍ',
    'NOVASOURCE DIABET PLUS VANILKOVÝ',
    'NOVETRON 8 MG DISPERGOVATELNÉ TABLETY',
    'NOVEZE',
    'NOVOEIGHT',
    'NOVOFEM',
    'NOVOMIX 30 FLEXPEN',
    'NOVOMIX 30 PENFILL',
    'NOVONORM',
    'NOVORAPID',
    'NOVORAPID FLEXPEN',
    'NOVORAPID PENFILL',
    'NOVORAPID PUMPCART',
    'NOVOSEVEN',
    'NOVÝ P-AM 2',
    'NOVÝ P-AM 3',
    'NOXAFIL',
    'NPLATE',
    'NUCALA',
    'NUTILIS CLEAR',
    'NUTILIS POWDER',
    'NUTRAMIGEN PURAMINO',
    'NUTRAMIGEN 1 LGG',
    'NUTRAMIGEN 2 LGG',
    'NUTRICOMP D',
    'NUTRICOMP DRINK D VANILKA',
    'NUTRICOMP DRINK PLUS BANÁN',
    'NUTRICOMP DRINK PLUS ČOKOLÁDA',
    'NUTRICOMP DRINK PLUS FIBRE BROSKEV, MERUŇKA',
    'NUTRICOMP DRINK PLUS FIBRE ČOKOLÁDA',
    'NUTRICOMP DRINK PLUS FIBRE KÁVA',
    'NUTRICOMP DRINK PLUS FIBRE MIX',
    'NUTRICOMP DRINK PLUS FIBRE VANILKA',
    'NUTRICOMP DRINK PLUS HP ČOKOLÁDA, NUGÁT',
    'NUTRICOMP DRINK PLUS HP VANILKA',
    'NUTRICOMP DRINK PLUS JAHODA',
    'NUTRICOMP DRINK PLUS MIX',
    'NUTRICOMP DRINK PLUS VANILKA',
    'NUTRICOMP DRINK RENAL VANILKA',
    'NUTRICOMP DRINK 2,0 KCAL FIBRE ČOKOLÁDOVÁ PRALINKA',
    'NUTRICOMP DRINK 2,0 KCAL FIBRE MIX',
    'NUTRICOMP DRINK 2,0 KCAL FIBRE MLÉČNÝ KARAMEL',
    'NUTRICOMP DRINK 2,0 KCAL FIBRE TŘEŠEŇ',
    'NUTRICOMP ENERGY',
    'NUTRICOMP ENERGY FIBRE',
    'NUTRICOMP ENERGY FIBRE NEUTRAL',
    'NUTRICOMP ENERGY HP',
    'NUTRICOMP ENERGY HP FIBRE',
    'NUTRICOMP HEPA',
    'NUTRICOMP HEPA SE SLADIDLEM, PŘÍCHUŤ ČOKOLÁDA',
    'NUTRICOMP PEPTID',
    'NUTRICOMP SOUP JEMNÉ KUŘECÍ KARI',
    'NUTRICOMP SOUP MIX',
    'NUTRICOMP SOUP ZELENINOVÁ POLÉVKA',
    'NUTRICOMP STANDARD',
    'NUTRICOMP STANDARD FIBRE',
    'NUTRICOMP STANDARD FIBRE D NEUTRAL',
    'NUTRICOMP STANDARD FIBRE NEUTRAL',
    'NUTRICOMP STANDARD NEUTRAL',
    'NUTRIDRINK BALÍČEK 5 + 1',
    'NUTRIDRINK BALÍČEK 5+1',
    'NUTRIDRINK COMPACT NEUTRAL',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ BANÁNOVOU',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ BROSKEV A MANGO',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ KÁVY',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ LESNÍHO OVOCE',
    'NUTRIDRINK COMPACT PROTEIN S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ BANÁNOVOU',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ KÁVY',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ LESNÍHO OVOCE',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ MERUŇKOVOU',
    'NUTRIDRINK COMPACT S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK CREME S PŘÍCHUTÍ BANÁNOVOU',
    'NUTRIDRINK CREME S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK CREME S PŘÍCHUTÍ LESNÍHO OVOCE',
    'NUTRIDRINK CREME S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK JUICE STYLE S PŘÍCHUTÍ JABLEČNOU',
    'NUTRIDRINK JUICE STYLE S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK MAX S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK MAX S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK MAX S PŘÍCHUTÍ KÁVY',
    'NUTRIDRINK MAX S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK MULTI FIBRE S PŘÍCHUTÍ BANÁNOVOU',
    'NUTRIDRINK MULTI FIBRE S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK MULTI FIBRE S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK MULTI FIBRE S PŘÍCHUTÍ POMERANČOVOU',
    'NUTRIDRINK MULTI FIBRE S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK NEUTRAL',
    'NUTRIDRINK PROTEIN S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK PROTEIN S PŘÍCHUTÍ LESNÍHO OVOCE',
    'NUTRIDRINK PROTEIN S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK S PŘÍCHUTÍ BANÁNOVOU',
    'NUTRIDRINK S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'NUTRIDRINK S PŘÍCHUTÍ JAHODOVOU',
    'NUTRIDRINK S PŘÍCHUTÍ KARAMELOVOU',
    'NUTRIDRINK S PŘÍCHUTÍ TROPICKÉHO OVOCE',
    'NUTRIDRINK S PŘÍCHUTÍ VANILKOVOU',
    'NUTRIDRINK YOGHURT S PŘÍCHUTÍ MALINA',
    'NUTRIDRINK YOGHURT S PŘÍCHUTÍ VANILKA A CITRÓN',
    'NUTRILAC BANÁN S',
    'NUTRILAC COFFEE S',
    'NUTRILAC NATURAL',
    'NUTRILAC NATURAL PLUS',
    'NUTRILAC VANILKA',
    'NUTRILAC VANILKA S',
    'NUTRILON NENATAL LCP',
    'NUTRILON PEPTI MCT',
    'NUTRILON 0 NENATAL',
    'NUTRILON 1 ALLERGY CARE',
    'NUTRILON 1 ALLERGY DIGESTIVE CARE',
    'NUTRILON 1 NENATAL',
    'NUTRILON 1 PEPTI',
    'NUTRILON 2 ALLERGY CARE',
    'NUTRILON 2 PEPTI',
    'NUTRINI',
    'NUTRINI MULTI FIBRE',
    'NUTRINI PEPTISORB',
    'NUTRINIDRINK NEUTRAL PRO DĚTI',
    'NUTRINIDRINK PRO DĚTI S VLÁKNINOU - BANÁNOVÁ PŘÍCHUŤ',
    'NUTRINIDRINK PRO DĚTI S VLÁKNINOU - ČOKOLÁDOVÁ PŘÍCHUŤ',
    'NUTRINIDRINK PRO DĚTI S VLÁKNINOU - JAHODOVÁ PŘÍCHUŤ',
    'NUTRINIDRINK PRO DĚTI S VLÁKNINOU - VANILKOVÁ PŘÍCHUŤ',
    'NUTRISEN BANÁN S BANÁNOVOU PŘÍCHUTÍ 200 ML',
    'NUTRISEN ČOKOLÁDA S ČOKOLÁDOVOU PŘÍCHUTÍ 200 ML',
    'NUTRISEN VANILKA S VANILKOVOU PŘÍCHUTÍ 200 ML',
    'NUTRISON',
    'NUTRISON ADVANCED CUBISON',
    'NUTRISON ADVANCED DIASON ENERGY HP S PŘÍCHUTÍ VANILKOVOU',
    'NUTRISON ADVANCED DIASON LOW ENERGY',
    'NUTRISON ADVANCED PEPTISORB',
    'NUTRISON ENERGY MULTI FIBRE',
    'NUTRISON MULTI FIBRE',
    'NUTRISON PROTEIN PLUS MULTI FIBRE',
    'NUTROPINAQ',
    'NUWIQ',
    'NYKOB',
    'OCAROX',
    'OCPLEX',
    'OCREVUS',
    'OCTAGAM',
    'OCTANATE',
    'OCTANATE 1 000 IU/5 ML',
    'OCTANATE 500 IU/5 ML',
    'OCTANINE F 1000',
    'OCTANINE F 500',
    'OCTAPLAS LG',
    'ODEFSEY',
    'OESTROGEL',
    'OFEV',
    'OFLOXACIN UNIMED PHARMA',
    'OFLOXACINO G.E.S.',
    'OFLOXACIN-POS',
    'OFLOXIN',
    'OFLOXIN INF',
    'OFNOL',
    'OFTABRIM',
    'OFTAN TIMOLOL 0,50%',
    'OFTAQUIX 5 MG/ML OČNÍ KAPKY',
    'OFTIDOR 2% OČNÍ KAPKY, ROZTOK',
    'OILATUM PLUS',
    'OLANZAPIN ACTAVIS',
    'OLANZAPIN APOTEX',
    'OLANZAPIN MYLAN',
    'OLANZAPIN SANDOZ',
    'OLANZAPIN TEVA',
    'OLAZAX',
    'OLAZAX DISPERZI',
    'OLFEN-100 SR',
    'OLICARD RETARD',
    'OLIMESTA',
    'OLOPATADIN ABDI',
    'OLPINAT',
    'OLTAR',
    'OLUMIANT',
    'OLWEXYA',
    'OMEPRAZOL ACTAVIS',
    'OMEPRAZOL AL 20',
    'OMEPRAZOL DISTRIQUIMICA',
    'OMEPRAZOL FARMAX',
    'OMEPRAZOL GALMED',
    'OMEPRAZOL MYLAN',
    'OMEPRAZOL OLIKLA',
    'OMEPRAZOL STADA',
    'OMEPRAZOL TEVA PHARMA',
    'OMNIC TOCAS 0,4',
    'OMNIPAQUE 300',
    'OMNIPAQUE 350',
    'OMNITROPE',
    'ONBREZ BREEZHALER',
    'ONDANSETRON ACCORD',
    'ONDANSETRON ARDEZ',
    'ONDANSETRON B. BRAUN',
    'ONDANSETRON KABI',
    'ONDANSETRON SANDOZ',
    'ONDANSETRON TEVA',
    'ONGLYZA',
    'OPATANOL',
    'OPDIVO',
    'OPHTHALMO-FRAMYKOIN',
    'OPHTHALMO-FRAMYKOIN COMP.',
    'OPHTHALMO-HYDROCORTISON LÉČIVA',
    'OPRYMEA',
    'OPTIRAY 300',
    'OPTIRAY 320',
    'OPTIRAY 350',
    'ORALAIR',
    'ORALAIR 100 IR & 300 IR',
    'ORAMELLOX',
    'ORCAL NEO',
    'ORENCIA',
    'ORFADIN',
    'ORFIRIL',
    'ORFIRIL LONG',
    'ORGALUTRAN',
    'ORGAMETRIL',
    'ORTANOL',
    'OSAGRAND',
    'OSMOLITE',
    'OSMOLITE HICAL',
    'OSPAMOX',
    'OSPEN',
    'OSPEN 1000',
    'OSPEN 1500',
    'OSPEN 500',
    'OSPEN 750',
    'OSPOLOT',
    'OSPORIL',
    'OSVAREN',
    'OTEZLA',
    'OTIPAX',
    'OTOBACID N',
    'OVALEAP',
    'OVESTIN',
    'OVITRELLE',
    'OVIXAN',
    'OXACILINA ATB',
    'OXALIPLATIN ACCORD',
    'OXALIPLATIN KABI',
    'OXALIPLATIN PHARMAGEN',
    'OXALIPLATIN TEVA',
    'OXALIPLATINA MEDAC',
    'OXALIPLATINA MYLAN',
    'OXAZEPAM LÉČIVA',
    'OXYCODON LANNACHER',
    'OXYCODON SANDOZ RETARD',
    'OXYCONTIN',
    'OXYKODON ACTAVIS',
    'OXYKODON DEVELCO',
    'OXYKODON MYLAN',
    'OXYKODON STADA',
    'OXYKODON TEVA',
    'OZURDEX',
    'PACLIMEDAC',
    'PACLITAXEL EBEWE',
    'PACLITAXEL KABI',
    'PACLITAXEL MYLAN',
    'PALEXIA',
    'PALEXIA RETARD',
    'PALGOTAL',
    'PALLADONE-SR',
    'PALONOSETRON ACCORD',
    'PALONOSETRON FRESENIUS KABI',
    'PALONOSETRON SANDOZ',
    'P-AM MATERNAL',
    'P-AM 2',
    'P-AM 3',
    'PAMBA',
    'PAMIDRONATE MEDAC',
    'PAMYCON NA PŘÍPRAVU KAPEK',
    'PANGROL',
    'PANGROL 20000',
    'PANTOMYL',
    'PANTOPRAZOL ACCORD',
    'PANTOPRAZOL OLIKLA',
    'PANTOPRAZOL +PHARMA',
    'PANTOPRAZOLE ZENTIVA',
    'PANZYGA',
    'PANZYNORM FORTE-N',
    'PANZYTRAT 25 000',
    'PARICALCITOL ACCORD',
    'PARICALCITOL FRESENIUS',
    'PARICALCITOL RAFARM',
    'PARICALCITOL TEVA',
    'PAROLEX',
    'PAROXETIN ORION',
    'PAROXETIN +PHARMA',
    'PARSABIV',
    'PARTRAMEC',
    'PAXIRASOL',
    'PECFENT',
    'PEGASYS',
    'PEGINTRON',
    'PELGRAZ',
    'PEMETREXED ACCORD',
    'PEMETREXED FRESENIUS KABI',
    'PEMETREXED GLENMARK',
    'PEMETREXED HEATON',
    'PEMETREXED KRKA',
    'PEMETREXED SANDOZ',
    'PENBENE',
    'PENESTER',
    'PENICILIN G 1,0 DRASELNÁ SOĹ BIOTIKA',
    'PENICILIN G 5,0 DRASELNÁ SOĹ BIOTIKA',
    'PENTASA',
    'PENTASA SACHET',
    'PENTASA SLOW RELEASE TABLETS 1 G',
    'PENTASA SLOW RELEASE TABLETS 500 MG',
    'PENTIRO',
    'PENTOMER RETARD',
    'PEPTAMEN AF',
    'PEPTAMEN JUNIOR',
    'PEPTAMEN NEUTRÁLNÍ',
    'PERGOVERIS',
    'PERINALON',
    'PERINALON COMBI',
    'PERINDOPRIL JS PARTNER',
    'PERINDOPRIL KRKA',
    'PERINDOPRIL MYLAN',
    'PERINDOPRIL PMCS',
    'PERINDOPRIL RATIOPHARM',
    'PERINDOPRIL/AMLODIPIN TEVA',
    'PERINDOPRIL/AMLODIPINE STADA',
    'PERINDOPRIL/INDAPAMID MYLAN',
    'PERINPA',
    'PERJETA',
    'PETINIMID',
    'PEVARYL',
    'PHARMTINA',
    'PHENAEMAL',
    'PHENAEMALETTEN',
    'PHENASEN',
    'PHLEXY-10 KAPSLE',
    'PHLEXY-10 TABLETY',
    'PHOSTAL',
    'PIASCLEDINE 300',
    'PICATO',
    'PICOPREP',
    'PIOGLITAZON ACTAVIS',
    'PIOGLITAZON MYLAN',
    'PIOGLITAZONE ACCORD',
    'PIOGLITAZONE TEVA',
    'PIRAMIL',
    'PIRAMIL COMBI',
    'PK-MERZ',
    'PKU AIR GOLD 15',
    'PKU AIR GOLD 20',
    'PKU AIR GREEN 15',
    'PKU AIR GREEN 20',
    'PKU ANAMIX FIRST SPOON',
    'PKU ANAMIX INFANT',
    'PKU ANAMIX JUNIOR BEZ PŘÍCHUTĚ',
    'PKU ANAMIX JUNIOR LQ - LESNÍ OVOCE',
    'PKU ANAMIX JUNIOR LQ-POMERANČ',
    'PKU ANAMIX JUNIOR S PŘÍCHUTÍ ČOKOLÁDOVOU',
    'PKU ANAMIX JUNIOR S PŘÍCHUTÍ LESNÍHO OVOCE',
    'PKU ANAMIX JUNIOR S PŘÍCHUTÍ POMERANČOVOU',
    'PKU ANAMIX JUNIOR S PŘÍCHUTÍ VANILKOVOU',
    'PKU COOLER 10 ORANGE',
    'PKU COOLER 10 PURPLE',
    'PKU COOLER 10 RED',
    'PKU COOLER 10 WHITE',
    'PKU COOLER 15 ORANGE',
    'PKU COOLER 15 PURPLE',
    'PKU COOLER 15 RED',
    'PKU COOLER 15 WHITE',
    'PKU COOLER 20 ORANGE',
    'PKU COOLER 20 ORANGE 30X174ML',
    'PKU COOLER 20 PURPLE',
    'PKU COOLER 20 PURPLE 30X174ML',
    'PKU COOLER 20 RED',
    'PKU COOLER 20 RED 30X174ML',
    'PKU COOLER 20 WHITE',
    'PKU COOLER 20 WHITE 30X174ML',
    'PKU EASY MICROTABS',
    'PKU EASY POWDER',
    'PKU EASY SHAKE&GO',
    'PKU EASY SHAKE&GO, NEUTRAL',
    'PKU EXPRESS 15 LEMON',
    'PKU EXPRESS 15 NEUTRAL',
    'PKU EXPRESS 15 ORANGE',
    'PKU EXPRESS 15 TROPICAL',
    'PKU GEL NEUTRAL',
    'PKU GEL ORANGE',
    'PKU GEL RASPBERRY',
    'PKU GO',
    'PKU LOPHLEX LQ ŠŤAVNATÉ PLODY',
    'PKU LOPHLEX LQ ŠŤAVNATÝ POMERANČ',
    'PKU LOPHLEX LQ 10 ŠŤAVNATÉ PLODY',
    'PKU LOPHLEX LQ 10 ŠŤAVNATÉ TROPICKÉ OVOCE',
    'PKU LOPHLEX LQ 10 ŠŤAVNATÝ CITRUS',
    'PKU LOPHLEX LQ 10 ŠŤAVNATÝ POMERANČ',
    'PKU LOPHLEX LQ 20 ŠŤAVNATÉ PLODY',
    'PKU LOPHLEX LQ 20 ŠŤAVNATÉ TROPICKÉ OVOCE',
    'PKU LOPHLEX LQ 20 ŠŤAVNATÝ CITRUS',
    'PKU LOPHLEX LQ 20 ŠŤAVNATÝ POMERANČ',
    'PKU LOPHLEX SENSATION MIX OVOCE',
    'PKU LOPHLEX SENSATION POMERANČ',
    'PKU NUTRI CONCENTRATED 2',
    'PKU NUTRI ENERGY 3',
    'PKU NUTRI ENERGY 3 S POMERANČOVOU PŘÍCHUTÍ',
    'PKU START',
    'PKU SYNERGY S PŘÍCHUTÍ CITRÓNOVOU',
    'PLADIZOL',
    'PLAQUENIL',
    'PLASMALYTE ROZTOK',
    'PLASMALYTE ROZTOK S GLUKÓZOU',
    'PLATEL',
    'PLAVOCORIN',
    'PLEGRIDY',
    'PLENDIL ER',
    'PLEXXO',
    'PLOVTT',
    'PMS-TESTOSTERONE',
    'POLINAIL',
    'POLLINEX RYE',
    'POLLINEX TREE',
    'POLMATINE',
    'POLYGYNAX',
    'PORTORA',
    'POTACTASOL',
    'PRADAXA',
    'PRAGIOLA',
    'PRALUENT',
    'PRAM',
    'PRAMIPEXOL MYLAN',
    'PRAMIPEXOL STADA',
    'PRE BEBA DISCHARGE',
    'PREDNI-POS',
    'PREDNISON LÉČIVA',
    'PREDUCTAL MR',
    'PREGABALIN ACCORD',
    'PREGABALIN ALPHA',
    'PREGABALIN APOTEX',
    'PREGABALIN BELUPO',
    'PREGABALIN MYLAN',
    'PREGABALIN MYLAN PHARMA',
    'PREGABALIN SANDOZ',
    'PREGABALIN STADA',
    'PREGABALIN STADA ARZNEIMITTEL AG',
    'PREGABALIN TEVA',
    'PREGABALIN ZENTIVA K.S.',
    'PREGAMID',
    'PREGLENIX',
    'PREGNYL 5000',
    'PRELICA',
    'PRENESSA',
    'PRENESSA ORO TAB',
    'PRENEWEL',
    'PRESID',
    'PRESTALIA',
    'PRESTANCE',
    'PRESTARIUM NEO',
    'PRESTARIUM NEO COMBI',
    'PRESTARIUM NEO FORTE',
    'PREVENAR 13',
    'PREZISTA',
    'PRIAMLO',
    'PRIMOVIST',
    'PRIVIGEN',
    'PROCORALAN',
    'PROGRAF',
    'PROHANCE',
    'PROKAIN PENICILIN G 1,5 BIOTIKA',
    'PROKANAZOL',
    'PROLEKOFEN',
    'PROLIA',
    'PROLUTEX',
    'PROMEMORE',
    'PRONTOFLEX 10%',
    'PROPAFENON AL 150',
    'PROPAFENON AL 300',
    'PROPANORM',
    'PROPOFOL 1% MCT/LCT FRESENIUS',
    'PROPOFOL 2% MCT/LCT FRESENIUS',
    'PROPOFOL-LIPURO 1 % (10MG/ML)',
    'PROPOFOL-LIPURO 2% (20MG/ML)',
    'PROPYCIL 50',
    'PROSTAPHLIN',
    'PROSTAVASIN',
    'PROSULPIN',
    'PROSURE BANÁNOVÁ PŘÍCHUŤ',
    'PROSURE ČOKOLÁDOVÁ PŘÍCHUŤ',
    'PROSURE KÁVOVÁ PŘÍCHUŤ',
    'PROSURE PŘÍCHUŤ BANÁNOVÁ',
    'PROSURE PŘÍCHUŤ ČOKOLÁDOVÁ',
    'PROSURE PŘÍCHUŤ KÁVOVÁ',
    'PROSURE PŘÍCHUŤ LESNÍHO OVOCE',
    'PROSURE PŘÍCHUŤ VANILKOVÁ',
    'PROSURE VANILKOVÁ PŘÍCHUŤ',
    'PROTELOS',
    'PROTEVASC',
    'PROTHAZIN',
    'PROTHIADEN',
    'PROTHROMPLEX TOTAL NF',
    'PROTOPIC',
    'PROTRADON',
    'PROVERA',
    'PROVIRSAN',
    'PT-AM 2',
    'PT-AM 3',
    'PTEROCYN',
    'PULMICORT',
    'PULMICORT TURBUHALER',
    'PULMOCARE 500 ML PŘÍCHUŤ VANILKA',
    'PULMOZYME',
    'PUREGON',
    'PURI-NETHOL',
    'PURINOL',
    'PURIVIST',
    'PYLERA',
    'QUAMATEL',
    'QUESTAX',
    'QUETAPO',
    'QUETIAPIN ACTAVIS',
    'QUETIAPIN MYLAN',
    'QUETIAPIN PMCS',
    'QUETIAPIN SANECA',
    'QUETIAPIN TEVA',
    'QUETIAPIN TEVA RETARD',
    'QUETIAPIN XANTIS',
    'QUETIAPINE ACCORD',
    'QUETIAPINE POLPHARMA',
    'QUINAPRIL/HYDROCHLOROTHIAZID AUROBINDO',
    'QUINAPRIL/HYDROCHLOROTHIAZIDE AUROVITAS',
    'QUTENZA',
    'QVIDADOTAX',
    'RABAKIR',
    'RABIPUR',
    'RAENOM',
    'RAMIL',
    'RAMIPRIL ACTAVIS',
    'RAMIPRIL H ACTAVIS',
    'RAMIPRIL H 2,5MG/12,5MG ACTAVIS',
    'RAMIPRIL H 5 MG/25 MG ACTAVIS',
    'RAMIPRIL JS PARTNER',
    'RAMIPRIL KRKA',
    'RAMIZEK',
    'RAMLADIO',
    'RAMOMARK',
    'RANISAN',
    'RANITAL',
    'RAPAMUNE',
    'RAPIFEN',
    'RAPOXOL',
    'RAVALSYO',
    'RAWEL SR',
    'REBETOL',
    'REBIF',
    'RECOMBINATE 1000 INTERNATIONAL UNIT/5 ML',
    'RECOMBINATE 500 INTERNATIONAL UNIT/5 ML',
    'RECOXA',
    'RECTODELT',
    'REFACTO AF',
    'REKOVELLE',
    'RELENZA',
    'RELISTOR',
    'RELPAX',
    'RELVAR ELLIPTA',
    'REMESTYP',
    'REMICADE',
    'REMIFENTANIL B. BRAUN',
    'REMODULIN',
    'REMOOD',
    'REMSIMA',
    'RENETEN',
    'RENUTRYL BOOSTER JAHODOVÁ PŘÍCHUŤ',
    'RENUTRYL BOOSTER KARAMELOVÁ PŘÍCHUŤ',
    'RENUTRYL BOOSTER KÁVOVÁ PŘÍCHUŤ',
    'RENUTRYL BOOSTER VANILKOVÁ PŘÍCHUŤ',
    'RENVELA',
    'REPAGLINIDE ACCORD',
    'REPATHA',
    'REPLAGAL',
    'REQUIP MODUTAB',
    'RESELIGO',
    'RESICAL',
    'RESOURCE DESSERT COMPLETE BROSKEV',
    'RESOURCE DESSERT COMPLETE KAKAO',
    'RESOURCE DESSERT COMPLETE KARAMEL',
    'RESOURCE DESSERT COMPLETE VANILKA',
    'RESOURCE DIABET PLUS PŘÍCHUŤ JAHODA',
    'RESOURCE DIABET PLUS PŘÍCHUŤ KÁVA',
    'RESOURCE DIABET PLUS PŘÍCHUŤ VANILKA',
    'RESOURCE JUNIOR FIBRE BANÁN',
    'RESOURCE JUNIOR FIBRE JAHODA',
    'RESOURCE JUNIOR FIBRE KAKAO',
    'RESOURCE JUNIOR FIBRE VANILKA',
    'RESOURCE PROTEIN ČOKOLÁDOVÝ 4X200 ML',
    'RESOURCE PROTEIN JAHODOVÝ 4X200 ML',
    'RESOURCE PROTEIN KÁVOVÝ 4X200 ML',
    'RESOURCE PROTEIN LESNÍ PLODY 4X200 ML',
    'RESOURCE PROTEIN MERUŇKOVÝ 4X200 ML',
    'RESOURCE PROTEIN VANILKOVÝ 4X200 ML',
    'RESOURCE THICKEN UP CLEAR 1X125GM',
    'RESOURCE 2,0 KCAL FIBRE ČOKOLÁDOVÝ',
    'RESOURCE 2,0 KCAL FIBRE KÁVOVÝ 4X200 ML',
    'RESOURCE 2,0 KCAL FIBRE LESNÍ PLODY 4X200 ML',
    'RESOURCE 2,0 KCAL FIBRE MERUŇKOVÝ 4X200 ML',
    'RESOURCE 2,0 KCAL FIBRE NEUTRÁLNÍ 4X200 ML',
    'RESOURCE 2,0 KCAL FIBRE VANILKOVÝ 4X200 ML',
    'RESOURCE 2,0 KCAL JAHODOVÝ 4X200 ML',
    'RESOURCE 2,0 KCAL MERUŇKOVÝ 4X200 ML',
    'RESOURCE 2,0 KCAL VANILKOVÝ 4X200 ML',
    'RESOURCE 2,5 COMPACT PŘÍCHUŤ BROSKEV - VANILKA',
    'RESOURCE 2,5 COMPACT PŘÍCHUŤ ČERNÝ RYBÍZ - MALINA',
    'RESPREEZA',
    'RESTIGULIN',
    'RETACRIT',
    'RETARPEN',
    'RETROVIR',
    'REVATIO',
    'REVLIMID',
    'REVOLADE',
    'REXTOL',
    'REYATAZ',
    'REZOLSTA',
    'RHEFLUIN',
    'RHESONATIV',
    'RHOPHYLAC 300 MIKROGRAMŮ/2 ML',
    'RIBAVIRIN MYLAN',
    'RIBOMUNYL',
    'RICEFAN',
    'RILEPTID',
    'RILMENIDIN TEVA',
    'RILUTEK',
    'RILUZOL PMCS',
    'RINGERFUNDIN B.BRAUN',
    'RINGER\'S INJECTION "FRESENIUS"',
    'RINGER\'S INJECTION FRESENIUS KABI',
    'RINGER\'S SOLUTION FRESENIUS KABI',
    'RINGERUV ROZTOK BRAUN',
    'RINGERŮV ROZTOK VIAFLO',
    'RISEDRONAT TEVA',
    'RISENDROS',
    'RISMYL',
    'RISPEN',
    'RISPERDAL',
    'RISPERDAL CONSTA',
    'RISPERIDON ACTAVIS',
    'RISPERIDON FARMAX',
    'RISPERIDON ORION',
    'RISPERIDON VIPHARM',
    'RITALIN',
    'RIVOCOR',
    'RIVODARON',
    'RIVOTRIL',
    'RIXATHON',
    'RIXUBIS',
    'ROACTEMRA',
    'ROCALTROL',
    'ROFERON-A',
    'ROLPRYNA',
    'ROPINIROL FARMAX',
    'ROPINIROL MYLAN',
    'ROPINIROLE STADA',
    'RORENDO ORO TAB',
    'ROSALOX',
    'ROSEMIG',
    'ROSEMIG SPRINTAB',
    'ROSI',
    'ROSUCARD',
    'ROSUDAPIN',
    'ROSUMOP',
    'ROSUMOP COMBI',
    'ROSUVASTATIN ACCORD',
    'ROSUVASTATIN AUROBINDO',
    'ROSUVASTATIN AUROVITAS',
    'ROSUVASTATIN JS PARTNER',
    'ROSUVASTATIN KRKA',
    'ROSUVASTATIN MYLAN',
    'ROSUVASTATIN NEWLINE PHARMA',
    'ROSUVASTATIN TCHAIKAPHARMA',
    'ROSUVASTATIN TEVA PHARMA',
    'ROSUVASTATIN/EZETIMIBE TEVA',
    'ROTICOX',
    'ROVAMYCINE',
    'ROVASYN',
    'ROXILIP',
    'ROXITHROMYCIN RATIOPHARM',
    'ROZEX',
    'RUCONEST',
    'RUZEB',
    'RYTMONORM',
    'SABRIL',
    'SAIZEN',
    'SALAZOPYRIN EN',
    'SALBUTAMOL WZF POLFA',
    'SALOFALK',
    'SALOFALK 500',
    'SALOZINAL',
    'SANDIMMUN NEORAL',
    'SANDOSTATIN LAR',
    'SANGONA',
    'SANGONA COMBI',
    'SASTRAVI',
    'SCANLUX',
    'SECTRAL',
    'SEDACORON',
    'SEEBRI BREEZHALER',
    'SEFOTAK',
    'SEMGLEE',
    'SENTACURIN',
    'SEPTANEST S ADRENALINEM 1:100 000',
    'SEPTANEST S ADRENALINEM 1:200 000',
    'SERDOLECT',
    'SERETIDE DISKUS',
    'SERETIDE 25/50 INHALER',
    'SEREVENT DISKUS',
    'SERKEP',
    'SEROPRAM',
    'SEROXAT',
    'SERTIVAN',
    'SERTRALIN ACTAVIS',
    'SERTRALIN APOTEX',
    'SERTRALIN JS PARTNER',
    'SERTRALIN KRKA',
    'SERTRALIN MYLAN',
    'SERTRALIN TEVA',
    'SERTRALIN VALE',
    'SERTRALIN VIPHARM',
    'SERTRALINE ACCORD',
    'SERTRALINE FARMAX',
    'SETALOFT',
    'SEVELAMER CARBONATE HEATON',
    'SEVELAMER CARBONATE MYLAN',
    'SEVELAMER CARBONATE WINTHROP',
    'SEVREDOL',
    'SICCAPROTECT',
    'SILGARD',
    'SILKIS',
    'SIMBRINZA',
    'SIMPONI',
    'SIMVASTATIN MYLAN',
    'SIMVASTATIN RATIOPHARM',
    'SINGULAIR',
    'SINGULAIR JUNIOR',
    'SINGULAIR 10',
    'SINGULAIR 4 MINI',
    'SINGULAIR 5 JUNIOR',
    'SIOFOR',
    'SIOFOR 1000',
    'SIOFOR 500',
    'SIOFOR 850',
    'SIRANALEN',
    'SIRDALUD',
    'SIRDUPLA',
    'SOBYCOR',
    'SOBYCOR COMBI',
    'SODIUM CHLORIDE FRESENIUS KABI 0,9%',
    'SOFENACOL',
    'SOLESMIN',
    'SOLIAN',
    'SOLIFENACIN ACCORD',
    'SOLIFENACIN APOTEX',
    'SOLIFENACIN FARMAX',
    'SOLIFENACIN G.L.PHARMA',
    'SOLIFENACIN MYLAN',
    'SOLIFENACIN PMCS',
    'SOLIFENACIN TEVA PHARMA',
    'SOLIFLOW',
    'SOLIXA',
    'SOLU-MEDROL',
    'SOLUPRICK SQ',
    'SOLUSIN',
    'SOMATULINE AUTOGEL',
    'SOMAVERT',
    'SONOVUE',
    'SOOLANTRA',
    'SORBIFER DURULES',
    'SORBIMON',
    'SORTIS',
    'SORVASTA',
    'SOTAHEXAL 160',
    'SOTAHEXAL 80',
    'SOVALDI',
    'SPASMED',
    'SPASMED 15',
    'SPASMOPAN',
    'SPERSADEX COMP.',
    'SPERSALLERG',
    'SPIOLTO RESPIMAT 2,5 MIKROGRAMŮ/2,5 MIKROGRAMŮ',
    'SPIRIVA',
    'SPIRIVA RESPIMAT',
    'SPORANOX',
    'SPRYCEL',
    'STACAPOLO',
    'STACYL',
    'STADAMET',
    'STADAPRESS',
    'STALEVO',
    'STALORAL',
    'STALORAL 300',
    'STAYVEER',
    'STELARA',
    'STERILE WATER FOR INJECTION "FRESENIUS"',
    'STIMULOTON',
    'STIVARGA',
    'STRATTERA',
    'STRIBILD',
    'STURIBAN',
    'STYGAPON',
    'SUBCUVIA 160 G/L',
    'SUBOXONE',
    'SUFENTA',
    'SUFENTA FORTE',
    'SUFENTANIL TORREX',
    'SULFASALAZIN K',
    'SULFASALAZIN K-EN',
    'SULIQUA',
    'SULPERAZON 2 G IM/IV',
    'SULPIROL 200',
    'SULPIROL 50',
    'SUMAMED',
    'SUMAMED FORTE',
    'SUMAMED STD',
    'SUMAMIGREN',
    'SUMATRIPTAN ACTAVIS',
    'SUMATRIPTAN MYLAN',
    'SUMATRIPTAN ORION',
    'SUMETROLIM',
    'SUMIGRA',
    'SUPPORTAN DRINK ANANAS-KOKOS',
    'SUPPORTAN DRINK CAPPUCCINO',
    'SUPPORTAN DRINK ČOKOLÁDA',
    'SUPPORTAN DRINK TROPICKÉ OVOCE',
    'SUPRACAIN 4%',
    'SUPRELIP',
    'SURAL',
    'SURGAM LÉČIVA',
    'SURVIMED OPD NEUTRAL',
    'SUSTANON 250',
    'SUTENT',
    'SUXAMETHONIUM CHLORID VUAB',
    'SYMBICORT',
    'SYMBICORT TURBUHALER 100 MIKROGRAMŮ/6 MIKROGRAMŮ/INHALACE',
    'SYMBICORT TURBUHALER 200 MIKROGRAMŮ/ 6 MIKROGRAMŮ/ INHALACE',
    'SYMBICORT TURBUHALER 400 MIKROGRAMŮ/12 MIKROGRAMŮ/INHALACE',
    'SYMTUZA',
    'SYNAGIS',
    'SYNAREL',
    'SYNFLORIX',
    'SYNJARDY',
    'SYNTOPHYLLIN',
    'SYNTOSTIGMIN',
    'TACHYBEN I.V.',
    'TADOGLEN',
    'TAFEN NASAL 50 MCG',
    'TAFINLAR',
    'TAFLOSIN',
    'TAFLOTAN',
    'TAGREN',
    'TAKIPRIN',
    'TALTZ',
    'TAMALIS',
    'TAMAYRA',
    'TAMIFLU',
    'TAMOXIFEN',
    'TAMOXIFEN "EBEWE"',
    'TAMSULOSIN HCL MYLAN',
    'TAMSULOSIN HCL SANDOZ 0,4',
    'TAMSULOSIN HCL SANDOZ 0,4 PROLONG',
    'TAMSULOSIN HCL TEVA',
    'TAMSULOSIN +PHARMA',
    'TAMUROX',
    'TANATRIL',
    'TANYZ',
    'TANYZ ERAS',
    'TARCEVA',
    'TARDYFERON',
    'TARDYFERON-FOL',
    'TARGIN',
    'TARGOCID',
    'TARGRETIN',
    'TARKA 180/2 MG TBL.',
    'TARKA 240/4 MG TBL.',
    'TASIGNA',
    'TASMAR',
    'TAVANIC I.V.',
    'TAXIMED',
    'TAXOL',
    'TAXOTERE',
    'TAZIP',
    'TEARS NATURALE II',
    'TECFIDERA',
    'TEGRETOL CR',
    'TELASSMO',
    'TELEBRIX GASTRO',
    'TELEBRIX 30 MEGLUMINE',
    'TELEBRIX 35',
    'TELMARK',
    'TELMISARTAN EGIS',
    'TELMISARTAN SANDOZ',
    'TELMISARTAN XANTIS',
    'TELMISARTAN/HYDROCHLOROTHIAZID EGIS',
    'TELMISARTAN/HYDROCHLOROTHIAZID RATIOPHARM',
    'TELMISARTAN/HYDROCHLOROTHIAZID SANDOZ',
    'TELMISARTAN/HYDROCHLOROTHIAZID XANTIS',
    'TELMISARTAN/HYDROCHLOROTHIAZIDE LICONSA',
    'TELMISARTAN-RATIOPHARM',
    'TELMIZEK COMBI',
    'TELZIR',
    'TEMODAL',
    'TEMOMEDAC',
    'TEMOZOLOMIDE ACCORD',
    'TEMOZOLOMIDE GLENMARK',
    'TEMOZOLOMIDE SUN',
    'TENAXUM',
    'TENLORIS',
    'TENOFOVIR DISOPROXIL MYLAN',
    'TENOFOVIR DISOPROXIL SANDOZ',
    'TENOFOVIR DISOPROXIL TEVA',
    'TENOLOC 200',
    'TENORETIC',
    'TENORMIN 100',
    'TENORMIN 50',
    'TENSAMIN',
    'TERBINAFIN ACTAVIS',
    'TERFIMED 250',
    'TERLIPRESIN ACETÁT EVER PHARMA',
    'TESAVEL',
    'TESTAVAN',
    'TESTIM',
    'TETMODIS',
    'TETRASPAN 6%',
    'TEVAGRASTIM',
    'TEVANEL',
    'TEVETEN',
    'TEYSUNO',
    'TEZEFORT',
    'TEZEO',
    'TEZEO HCT',
    'TEZZIMI',
    'THEOPLUS 100',
    'THEOPLUS 300',
    'THIOCTACID 600 T',
    'THIOGAMMA TURBO SET',
    'THIOPENTAL PHARMASWISS',
    'THIOPENTAL VALEANT',
    'THIOPENTAL VUAB INJ. PLV. SOL. 0,5 G',
    'THIOPENTAL VUAB INJ. PLV. SOL. 1,0 G',
    'THROMBOREDUCTIN',
    'THYROGEN',
    'THYROZOL',
    'TIANEPTIN MYLAN',
    'TIAPRA',
    'TIAPRALAN',
    'TIAPRID PMCS',
    'TIAPRIDAL',
    'TIENAM 500 MG/500 MG I.V.',
    'TIGECYCLINE MYLAN',
    'TIGECYCLINE SANDOZ',
    'TILADE MINT',
    'TIMO-COMOD 0,5%',
    'TIMOLOL UNIMED PHARMA',
    'TIMOLOL-POS 0,5%',
    'TIMONIL 150 RETARD',
    'TIMONIL 300 RETARD',
    'TIMONIL 600 RETARD',
    'TINKAIR 100 MCG',
    'TINKAIR 50 MCG',
    'TINTAROS',
    'TISERCIN',
    'TIVICAY',
    'TOBI NEBULISER SOLUTION',
    'TOBI PODHALER',
    'TOBRADEX',
    'TOBREX',
    'TOBREX LA',
    'TOLNEXA',
    'TOLUCOMBI',
    'TOLURA',
    'TOMUDEX',
    'TONANDA',
    'TONARSSA',
    'TOPAMAX',
    'TOPILEX',
    'TOPIMARK',
    'TOPIRAMAT ACTAVIS',
    'TOPIRAMAT MYLAN',
    'TOPIRAMAT SANDOZ',
    'TOPOTECAN ACCORD',
    'TOPOTECAN HOSPIRA',
    'TOPOTECAN PHARMAGEN',
    'TOPOTECAN TEVA',
    'TORECAN',
    'TORISEL',
    'TORVACARD NEO',
    'TORVAZIN',
    'TOUJEO',
    'TOVIAZ',
    'TRACRIUM 50',
    'TRACTOCILE',
    'TRAJENTA',
    'TRALGIT',
    'TRALGIT GTT.',
    'TRALGIT OROTAB',
    'TRALGIT SR 100',
    'TRALGIT SR 150',
    'TRALGIT SR 200',
    'TRALGIT 100 INJ',
    'TRALGIT 50 INJ',
    'TRAMABENE',
    'TRAMADOL KALCEKS',
    'TRAMADOL KRKA',
    'TRAMADOL MYLAN',
    'TRAMADOL RETARD ACTAVIS',
    'TRAMADOL SANDOZ RETARD',
    'TRAMADOL SANECA',
    'TRAMADOL XANTIS',
    'TRAMAL',
    'TRAMAL KAPKY 100 MG/1 ML',
    'TRAMAL RETARD TABLETY 100 MG',
    'TRAMAL RETARD TABLETY 150 MG',
    'TRAMAL RETARD TABLETY 200 MG',
    'TRAMAL TOBOLKY 50 MG',
    'TRAMUNDIN RETARD',
    'TRAMYLPA',
    'TRANDATE',
    'TRANDOLAPRIL RATIOPHARM',
    'TRANEXAMIC ACID ACCORD',
    'TRANSTEC',
    'TRAVATAN',
    'TRAVOPROST STADA',
    'TRELEMA',
    'TRENTAL 400',
    'TREVICTA',
    'TREXAN',
    'TRIAMCINOLON E LÉČIVA',
    'TRIAMCINOLON LÉČIVA CRM',
    'TRIAMCINOLON LÉČIVA UNG',
    'TRIAMCINOLON S LÉČIVA',
    'TRIAMCINOLON TEVA',
    'TRIASYN',
    'TRIGELAN',
    'TRIMBOW',
    'TRIMETAZIDIN MYLAN',
    'TRIMETAZIDIN TEVA RETARD',
    'TRIPLIXAM',
    'TRIPRIM',
    'TRISENOX',
    'TRISEQUENS',
    'TRISPAN',
    'TRITACE',
    'TRITACE COMBI',
    'TRITAZIDE',
    'TRITTICO AC 150',
    'TRITTICO AC 75',
    'TRITTICO PROLONG',
    'TRIUMEQ',
    'TRIXID',
    'TRIZIVIR',
    'TROMBEX',
    'TROZARA',
    'TROZEL',
    'TRULICITY',
    'TRUMENBA',
    'TRUND',
    'TRUSOPT',
    'TRUVADA',
    'TRUXIMA',
    'TULIP',
    'TUTUS',
    'TWICOR',
    'TWYNSTA',
    'TYGACIL',
    'TYR ANAMIX INFANT',
    'TYREZ',
    'TYSABRI',
    'TYVERB',
    'UBISTESIN',
    'UBISTESIN FORTE',
    'UBRETID',
    'UCD ANAMIX INFANT',
    'ULFAMID 20',
    'ULFAMID 40',
    'ULTIBRO BREEZHALER',
    'ULTOP 20',
    'ULTRACOD',
    'ULTRAVIST 300',
    'ULTRAVIST 370',
    'UNASYN',
    'UNDESTOR',
    'UNILAT 50 MIKROGRAMŮ/ML, OČNÍ KAPKY, ROZTOK',
    'UNIPRES 10',
    'UNIPRES 20',
    'UNO',
    'URALYT-U',
    'URAPLEX',
    'URIZIA',
    'UROFLOW',
    'UROREC',
    'URO-VAXOM',
    'UROXAL',
    'URSOFALK',
    'URSOFALK SUSPENZE',
    'URSOGRIX',
    'URSONORM',
    'URSOSAN',
    'URSOSAN FORTE',
    'UTROGESTAN',
    'VACTETA',
    'VAGIFEM',
    'VALACICLOVIR MYLAN',
    'VALACICLOVIR +PHARMA',
    'VALCYTE',
    'VALDAMIN',
    'VALDOXAN',
    'VALGANCICLOVIR MYLAN',
    'VALGANCICLOVIR SANDOZ',
    'VALGANCICLOVIR TEVA',
    'VALPROAT CHRONO SANDOZ',
    'VALPROAT RATIOPHARM CHRONO',
    'VALSACOMBI',
    'VALSACOR',
    'VALTREX',
    'VALZAP',
    'VALZAP COMBI',
    'VANATEX HCT',
    'VANCOMYCIN HIKMA',
    'VANCOMYCIN KABI',
    'VANCOMYCIN MYLAN',
    'VANTAVO',
    'VAPRESS',
    'VASILIP',
    'VASOCARDIN SR 200',
    'VASOCARDIN 100',
    'VASOCARDIN 50',
    'VASOPIRIN',
    'VASOSAN P',
    'VAXIGRIP',
    'VAXIGRIP TETRA',
    'VECTIBIX',
    'VELAXIN',
    'VELCADE',
    'VELETRI',
    'VELLOFENT',
    'VELMETIA',
    'VELPHORO',
    'VENDAL RETARD',
    'VENLAFAXIN MYLAN',
    'VENOFER',
    'VENTAVIS',
    'VENTER',
    'VENTOLIN',
    'VENTOLIN INHALER N',
    'VERAHEXAL KHK RETARD',
    'VERAHEXAL RR',
    'VERAL',
    'VERAL 100 RETARD',
    'VERAL 75 RETARD',
    'VERAPAMIL AL 240 RETARD',
    'VERMOX',
    'VEROGALID ER',
    'VERORAB',
    'VEROSPIRON',
    'VERRIA',
    'VERSATIS',
    'VERTIBETIS',
    'VERTIMED',
    'VESICARE',
    'VESSEL DUE F',
    'VFEND',
    'VIAZET',
    'VICTOZA',
    'VIDAZA',
    'VIDISIC',
    'VIDONORM',
    'VIEKIRAX',
    'VIGAMOX',
    'VIGAMOX 5 MG/ML OČNÍ KAPKY',
    'VIGANTOL',
    'VIGIL',
    'VIMPAT',
    'VINBLASTIN TEVA',
    'VINCRISTINE TEVA',
    'VINORELBINE ACCORD',
    'VIPDOMET',
    'VIPIDIA',
    'VIREAD',
    'VIREGYT-K',
    'VIREXAN',
    'VIRGAN',
    'VISANNE',
    'VISIPAQUE',
    'VISTAGAN LIQUIFILM 0,5%',
    'VISTAPREP',
    'VISUDYNE',
    'VITAMIN A-SLOVAKOFARMA',
    'VITAMIN B12 LÉČIVA',
    'VIZIBIM',
    'VODA NA INJEKCI VIAFLO',
    'VOKANAMET',
    'VOLIBRIS',
    'VOLNOSTIN',
    'VOLTAREN',
    'VOLTAREN OPHTHA CD',
    'VOLTAREN RAPID',
    'VOLTAREN RETARD',
    'VOLUVEN',
    'VORICONAZOL POLPHARMA',
    'VORICONAZOLE ACCORD',
    'VORICONAZOLE FRESENIUS KABI',
    'VORICONAZOLE MEDOCHEMIE',
    'VORICONAZOLE STADA',
    'VORICONAZOLE TEVA',
    'VORIKONAZOL ACTAVIS',
    'VORIKONAZOL MYLAN',
    'VORIKONAZOL SANDOZ',
    'VOSEVI',
    'VOTRIENT',
    'V-PENICILIN 0,8 MEGA BIOTIKA',
    'V-PENICILIN 1,2 MEGA BIOTIKA',
    'VPRIV',
    'VULMIZOLIN',
    'WARFARIN ORION',
    'WARFARIN PMCS',
    'WARTEC CREAM',
    'WATER FOR INJECTION FRESENIUS KABI',
    'WELLBUTRIN SR',
    'WILATE 1000',
    'WILATE 500',
    'XADOS',
    'XALACOM',
    'XALATAN',
    'XALEEC',
    'XALEEC COMBI',
    'XALKORI',
    'XALOPTIC',
    'XALOPTIC COMBI',
    'XAMIOL',
    'XANAX',
    'XANAX SR',
    'XARELTO',
    'XEFO RAPID',
    'XELJANZ',
    'XELODA',
    'XENETIX 300',
    'XENETIX 350',
    'XEOMIN',
    'XEPLION',
    'XGEVA',
    'XIAPEX',
    'XIGDUO',
    'XOLAIR',
    'XORIMAX',
    'XP MAXAMUM',
    'XP MAXAMUM S PŘÍCHUTÍ POMERANČOVOU',
    'XPHE ADVANCE SE',
    'XPHE INFANT MIX LCP',
    'XPHE JUNIOR SE',
    'XPHE KID SE',
    'XTANDI',
    'XULTOPHY',
    'XYZAL',
    'YAL',
    'YASNAL',
    'YASNAL ORO TAB',
    'YELLOX',
    'YERVOY',
    'ZABCARE',
    'ZADITEN SDU 0,025%',
    'ZAHRON',
    'ZAHRON COMBI',
    'ZALASTA',
    'ZALDIAR',
    'ZALDIAR EFFERVESCENS',
    'ZALTRAP',
    'ZARZIO',
    'ZAVEDOS',
    'ZAVESCA',
    'ZEBINIX',
    'ZEFFIX',
    'ZEGOMIB',
    'ZELBORAF',
    'ZELDOX',
    'ZEMPLAR',
    'ZENARO',
    'ZENMEM',
    'ZENON',
    'ZEPATIER',
    'ZEVESIN',
    'ZIAGEN',
    'ZIBOR',
    'ZINACEF',
    'ZINERYT',
    'ZINNAT',
    'ZIPRASIDON MYLAN',
    'ZITROCIN',
    'ZNOBACT',
    'ZODAC',
    'ZOFRAN',
    'ZOFRAN ZYDIS',
    'ZOLADEX DEPOT',
    'ZOLAFREN',
    'ZOLAFREN RAPID',
    'ZOLEDRONIC ACID ACCORD',
    'ZOLEDRONIC ACID ACTAVIS',
    'ZOLEDRONIC ACID FRESENIUS KABI',
    'ZOLEDRONIC ACID HOSPIRA',
    'ZOLEDRONIC ACID MEDAC',
    'ZOLEDRONIC ACID MYLAN',
    'ZOLEDRONIC ACID PHARMAGEN',
    'ZOLEDRONIC ACID TEVA',
    'ZOLEPTIL 25',
    'ZOLEPTIL 50',
    'ZOLETORV',
    'ZOLOFT',
    'ZOMACTON',
    'ZOMETA',
    'ZOMIKOS',
    'ZONEGRAN',
    'ZONIBON',
    'ZONISAMID SANDOZ',
    'ZONISAMIDE MYLAN',
    'ZONISAMIDE URQUIMA',
    'ZOREM',
    'ZOVIRAX',
    'ZOXON',
    'ZULBEX',
    'ZYDELIG',
    'ZYKALOR',
    'ZYLLT',
    'ZYNZOL',
    'ZYPADHERA',
    'ZYPREXA',
    'ZYPREXA VELOTAB',
    'ZYPSILAN',
    'ZYRTEC',
    'ZYTIGA',
    'ZYVOXID',
    '0,9% SODIUM CHLORIDE IN WATER FOR INJECTION "FRESENIUS"',
    '0,9% SODIUM CHLORIDE INTRAVENOUS INFUSION BP BAXTER',
    '10% GLUCOSE IN WATER FOR INJECTION FRESENIUS',
    '20% GLUCOSE IN WATER FOR INJECTION FRESENIUS',
    '20% MANNITOL IN WATER FOR INJECTION FRESENIUS',
    '2,5%GLUCOSE+0,45% SODIUM CHLORIDE IN WATER FOR INJECTION FRESENIUS',
    '5% GLUCOSE IN WATER FOR INJECTION FRESENIUS',
    '5-FLUOROURACIL EBEWE',
];
