import 'jquery';
import 'bootstrap';
import {registerRemigFeatures} from "./remig";
import {registerCsrf} from "./csrf";
import {registerEcrf} from "./ecrf";
import {toRemigDate} from "./date";

$(window).on('load', () => {
    registerCsrf();
    registerEcrf();
    registerRemigFeatures();

    $(".clickable-row").click(function() {
        window.location = $(this).data("href");
    });

    $('.dashboard-date').datepicker({
        dateFormat: 'yy-mm-dd',
        closeText: "Zavřít",
        prevText: "&#x3C;Dříve",
        nextText: "Později&#x3E;",
        currentText: "Nyní",
        monthNames: [ "leden","únor","březen","duben","květen","červen",
            "červenec","srpen","září","říjen","listopad","prosinec" ],
        monthNamesShort: [ "led","úno","bře","dub","kvě","čer",
            "čvc","srp","zář","říj","lis","pro" ],
        dayNames: [ "neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota" ],
        dayNamesShort: [ "ne", "po", "út", "st", "čt", "pá", "so" ],
        dayNamesMin: [ "ne","po","út","st","čt","pá","so" ],
        weekHeader: "Týd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ""
    });
});
